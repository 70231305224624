import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '..';
import { usePendingTransactionCount } from '../transaction/hooks';
import { requestUpdate } from './actions';

const Updater: React.FC = () => {
  const dispatch = useDispatch();
  const pendingTransactionCount = usePendingTransactionCount();
  const lastUpdate = useSelector((s: AppState) => s.application.lastUpdate);

  useEffect(() => {
    dispatch(requestUpdate());
  }, [dispatch, pendingTransactionCount]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(requestUpdate());
    }, 30e3);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, lastUpdate]);

  return null;
};

export default Updater;
