import { createAction } from '@reduxjs/toolkit';

export const transcationSubmitted = createAction<{
  hash: string;
  summary: string;
  extras?: any;
  popupId: string;
}>('transaction/submitted');

export const transactionConfirmed = createAction<{
  hash: string;
  error?: string;
}>('transaction/confirmed');
