import { Program, Provider } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import config from '../config';
import OracleIdl from '../idl/twap-oracle.json';
import { PriceAccount } from './accounts';
import { ORACLE_PROGRAM_ID } from './constants';

export class Oracle {
  private program: Program;
  private address: PublicKey;

  constructor(address: string, private provider: Provider) {
    this.address = new PublicKey(address);
    this.program = new Program(OracleIdl as any, ORACLE_PROGRAM_ID, provider);
  }

  async info(price: PublicKey) {
    if (!price) {
      return;
    }
    const data = (await this.program.account.price.fetch(price)) as PriceAccount;
    return data;
  }

  async sharePrice() {
    const data = await this.info(new PublicKey(config.stableCoin.oracles.share));
    return data.tokenPrice;
  }

  async dollarPrice() {
    const data = await this.info(new PublicKey(config.stableCoin.oracles.dollar));
    return data.tokenPrice;
  }
}
