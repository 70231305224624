import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BigNumberValue } from '../../components/BigNumberValue';
import { TokenInputWithMaxButton } from '../../components/TokenInput';
import { useTokenSwap } from '../../providers/ChainProvider';
import { useSlippage } from '../../state/application/hooks';
import {
  selectTokenAccountBySymbol,
  selectTokenBalanceBySymbol,
} from '../../state/token/selectors';
import { useTransactionHandler } from '../../hooks/useTransactionHandler';
import { Zero } from '../../utils/bignumber';
import { TokenSymbol } from '../../components/TokenSymbol';
import {
  Balance,
  Box,
  ButtonContainer,
  StyledInputContainer,
  TokenInputHeader,
  TokenLabel,
} from '../../components/Box';
import { Button } from '../../components/Button';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from '../../components/Dropdown';
import When from '../../components/When';
import { selectPair } from '../../config/tokens';
import ModalSelectWallet from '../../components/ModalAccount/ModalSelectWallet';
import useModal from '../../hooks/useModal';
import { useSwapInfo } from '../../hooks/useSwapInfo';

const tokens = ['USDC', 'IRON', 'ICE'];

const AddLiquidity: React.FC = () => {
  const tokenSwap = useTokenSwap();
  const [tokenAAmount, setTokenAAmount] = useState<BN>();
  const [tokenBAmount, setTokenBAmount] = useState<BN>();
  const [output, setOutput] = useState<BN>();
  const tokenBalances = useSelector(selectTokenBalanceBySymbol);
  const tokenAccounts = useSelector(selectTokenAccountBySymbol);
  const wallet = useWallet();
  const handleTransaction = useTransactionHandler();
  const [tokenA, setTokenA] = useState<string>('USDC');
  const [tokenB, setTokenB] = useState<string>('ICE');
  
  const pair = useMemo(() => {
    return selectPair(tokenA, tokenB);
  }, [tokenA, tokenB]);
  
  const poolInfo = useSwapInfo(pair?.swap)

  const slippage = useSlippage();
  useEffect(() => {
    if (
      !tokenAAmount ||
      !tokenBAmount ||
      tokenAAmount.eq(Zero) ||
      tokenBAmount.eq(Zero) ||
      !poolInfo
    ) {
      return;
    }

    setOutput(
      tokenSwap.estimateAddLiquidity(
        new PublicKey(pair.swap),
        tokenAAmount,
        tokenBAmount,
        slippage,
        poolInfo.lpSupply,
        poolInfo.reserveA,
        poolInfo.reserveB,
      ),
    );
  }, [tokenBAmount, tokenAAmount, tokenSwap, pair, slippage, poolInfo]);

  const add = useCallback(async () => {
    if (!pair || !wallet.connected) {
      return;
    }

    const [_tokenA, _tokenB] =
      pair.tokenA === tokenA ? [tokenA, tokenB] : [tokenB, tokenA];
    const [_tokenAAmount, _tokenBAmount] =
      pair.tokenA === tokenA
        ? [tokenAAmount, tokenBAmount]
        : [tokenBAmount, tokenAAmount];
    const tx = tokenSwap.addLiquidity(
      new PublicKey(pair.swap),
      wallet.publicKey,
      tokenAccounts[_tokenA],
      tokenAccounts[_tokenB],
      _tokenAAmount,
      _tokenBAmount,
      output,
      tokenAccounts[pair.lp],
    );

    await handleTransaction(tx, {
      type: 'swap:addLiquidity',
      tokenA: _tokenA,
      tokenB: _tokenB,
      tokenAAmount,
      tokenBAmount,
    });
  }, [
    handleTransaction,
    output,
    pair,
    tokenA,
    tokenAAmount,
    tokenAccounts,
    tokenB,
    tokenBAmount,
    tokenSwap,
    wallet,
  ]);

  const buttonState = useMemo(() => {
    if (!pair) {
      return 'noPair';
    }

    if (!wallet.connected) {
      return 'notConnect';
    }

    return 'ready';
  }, [pair, wallet]);

  const buttonText = useMemo(() => {
    switch (buttonState) {
      case 'noPair': {
        return 'No pair found';
      }
      case 'notConnect': {
        return 'Connect wallet';
      }

      default:
        return 'Add liquidity';
    }
  }, [buttonState]);

  const [connect] = useModal(<ModalSelectWallet />);

  const onAddClick = useCallback(() => {
    if (!wallet.connected) {
      connect();
    } else {
      add();
    }
  }, [add, connect, wallet]);

  return (
    <StyledContainer>
      <h2>Add liquidity</h2>
      <Box>
        <StyledInputContainer>
          <TokenInputHeader>
            <Dropdown>
              <DropdownToggle>
                <TokenLabel>
                  <TokenSymbol symbol={tokenA} />
                  <strong>{tokenA}</strong>
                  <i className="fas fa-caret-down"></i>
                </TokenLabel>
              </DropdownToggle>
              <DropdownMenu>
                <StyledDropdownList>
                  {tokens.map((t) => (
                    <li key={t} onClick={() => setTokenA(t)}>
                      <TokenSymbol size={28} symbol={t} /> {t}
                    </li>
                  ))}
                </StyledDropdownList>
              </DropdownMenu>
            </Dropdown>
            <When condition={!!tokenBalances[tokenA]}>
              <Balance>
                <BigNumberValue value={tokenBalances[tokenA]} decimals={6} />
              </Balance>
            </When>
          </TokenInputHeader>

          <div>
            <TokenInputWithMaxButton
              value={tokenAAmount}
              maxValue={tokenBalances[tokenA]}
              onChange={setTokenAAmount}
              decimals={6}
            />
          </div>
        </StyledInputContainer>
        <Plus />

        <StyledInputContainer>
          <TokenInputHeader>
            <Dropdown>
              <DropdownToggle>
                <TokenLabel>
                  <TokenSymbol symbol={tokenB} />
                  <strong>{tokenB}</strong>
                  <i className="fas fa-caret-down"></i>
                </TokenLabel>
              </DropdownToggle>
              <DropdownMenu>
                <StyledDropdownList>
                  {tokens.map((t) => (
                    <li key={t} onClick={() => setTokenB(t)}>
                      <TokenSymbol size={28} symbol={t} /> {t}
                    </li>
                  ))}
                </StyledDropdownList>
              </DropdownMenu>
            </Dropdown>
            <When condition={!!tokenBalances[tokenB]}>
              <Balance>
                <BigNumberValue value={tokenBalances[tokenB]} decimals={6} />
              </Balance>
            </When>
          </TokenInputHeader>

          <div>
            <TokenInputWithMaxButton
              value={tokenBAmount}
              maxValue={tokenBalances[tokenB]}
              onChange={setTokenBAmount}
              decimals={6}
            />
          </div>
        </StyledInputContainer>
        <Output>
          You will receive: <BigNumberValue value={output} decimals={6} />{' '}
          {pair?.lp}
        </Output>
        <ButtonContainer>
          <Button block onClick={onAddClick}>
            {buttonText}
          </Button>
        </ButtonContainer>

        <Info>
          <h5>Pool liquidity:</h5>

          <div>
            <strong>{pair?.tokenA}</strong>{' '}
            <BigNumberValue value={poolInfo?.reserveA} decimals={6} />
          </div>
          <div>
            <strong>{pair?.tokenB}</strong>{' '}
            <BigNumberValue value={poolInfo?.reserveB} decimals={6} />
          </div>
        </Info>
      </Box>
    </StyledContainer>
  );
};

export default AddLiquidity;

const Plus = styled.div.attrs({
  children: <i className="fas fa-plus"></i>,
})`
  text-align: center;
  padding: 20px;
`;

const Output = styled.div`
  padding: 20px 0 0 0;
`;

const Info = styled.div`
  padding-top: 20px;
`;

const StyledContainer = styled.div`
  margin: auto;
  max-width: 32em;
`;

const StyledDropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #d0dddd;
    }
  }
`;
