import { Provider } from '@project-serum/anchor';
import config from '../config';
import { Chef } from './Chef';
import { Oracle } from './Oracle';
import { Pool } from './Pool';
import { PoolManager } from './PoolManager';
import { TokenSwap } from './TokenSwap';

export interface IChain {
  getPool(): Pool;
  getChef(): Chef;
  getTokenSwap(): TokenSwap;
  getPoolManager(): PoolManager;
  getOracle(): Oracle;
}

export class Chain implements IChain {
  private pool: Pool;
  private chef: Chef;
  private tokenSwap: TokenSwap;
  private poolManager: PoolManager;
  private oracle: Oracle;

  constructor(private provider: Provider) {}

  getPool(): Pool {
    if (!this.pool) {
      this.pool = new Pool(config.stableCoin.pool, this.provider);
    }

    return this.pool;
  }

  getChef(): Chef {
    if (!this.chef) {
      this.chef = new Chef(config.farm.chef, this.provider);
    }

    return this.chef;
  }

  getTokenSwap(): TokenSwap {
    if (!this.tokenSwap) {
      this.tokenSwap = new TokenSwap(this.provider);
    }

    return this.tokenSwap;
  }

  getPoolManager(): PoolManager {
    if (!this.poolManager) {
      this.poolManager = new PoolManager(config.stableCoin.pool, this.provider);
    }

    return this.poolManager;
  }
  getOracle(): Oracle {
    if (!this.oracle) {
      this.oracle = new Oracle(config.stableCoin.pool, this.provider);
    }

    return this.oracle;
  }
}
