import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Icon} from '../../assets/icons/waiting.svg';

export type WaitingProps = {
  message: string;
  title?: string;
};

export const WaitingPopup: React.FC<WaitingProps> = ({ message, title }) => {
  return (
    <Container>
      <StyledIcon>
        <Icon />
      </StyledIcon>
      <div>
        {title ? <Title>{title}</Title> : null}
        <span>{message}</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #ff8e35;
    border-radius: 5px 0 0 5px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  color: #ff8e35;
`;

const StyledIcon = styled.div`
  margin-right: 15px;
  flex-shrink: 0;
  font-size: 1.2rem;
`;
