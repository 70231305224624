import { Modal, ModalBody, ModalClose, ModalHeader, ModalTitle } from '../../providers/Modals';
import TransactionList from './TransactionList';

export type ModalAccountProps = {
  onDismiss?: () => void;
};

const ModalAccount: React.FC<ModalAccountProps> = ({ onDismiss }) => {
  return (
    <Modal size="sm">
      <ModalHeader>
        <ModalTitle>Transaction history</ModalTitle>
        <ModalClose onClick={onDismiss} />
      </ModalHeader>
      <ModalBody>
        <TransactionList />
      </ModalBody>
    </Modal>
  );
};

export default ModalAccount;
