import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TransactionSelectors } from '../../state/transaction/hooks';
import { ExplorerLink } from '../ExplorerLink';

const TransactionList: React.FC = () => {
  const transactions = useSelector(TransactionSelectors.selectAll);

  return (
    <StyledContainer>
      {transactions.map((tx) => (
        <StyledItem key={tx.hash}>
          {!tx.confirmed && !tx.error ? (
            <i className="far fa-circle-notch fa-spin" style={{ color: '#6e6f7b' }} />
          ) : null}
          {tx.confirmed ? (
            <i className="far fa-check-circle" style={{ color: '#1aa04b' }} />
          ) : null}
          {tx.error ? (
            <i className="far fa-exclamation-circle" style={{ color: '#d96363' }} />
          ) : null}
          <ExplorerLink type="tx" address={tx.hash}>
            {tx.summary ?? tx.hash}
            <i className="far fa-external-link"></i>
          </ExplorerLink>
        </StyledItem>
      ))}
    </StyledContainer>
  );
};

export default TransactionList;

const StyledContainer = styled.div``;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    padding-bottom: 2px;
  }
  a {
    flex: 1;
    display: flex;
    margin-left: 13px;
    font-size: 16px;
    font-weight: normal;
    color: #16182c;
    i {
      font-size: 14px;
      margin-left: auto;
      color: #64a8a6;
    }
  }
  :not(:last-child) {
    padding-bottom: 20px;
  }
`;
