import React, { ReactText } from 'react';
import USDC from '../assets/tokens/USDC.png';
import ICE from '../assets/tokens/ICE.png';
import IRON from '../assets/tokens/IRON.png';

export type TokenSymbolProps = {
  symbol: string;
  size?: ReactText;
  autoHeight?: boolean;
};

const logosBySymbol: { [title: string]: string } = {
  USDC: USDC,
  ICE: ICE,
  IRON: IRON,
};

export const TokenSymbol: React.FC<TokenSymbolProps> = ({
  symbol,
  size = 32,
  autoHeight = false,
}) => {
  if (!symbol) {
    return <></>;
  }
  return (
    <img
      className="token-symbol"
      src={logosBySymbol[symbol.toUpperCase()] || logosBySymbol.DEFAULT}
      alt={`${symbol} Logo`}
      width={size}
      height={autoHeight ? 'auto' : size}
    />
  );
};
