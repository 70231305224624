import { useEffect, useState } from 'react';
import { useTokenSwap } from '../providers/ChainProvider';
import { PublicKey } from '@solana/web3.js';

export const useSwapInfo = (pair: string) => {
  const [data, setData] = useState<any>();
  const tokenSwap = useTokenSwap();

  useEffect(() => {
    let mounted = true;
    if (!pair) {
      return;
    }
    tokenSwap?.info(new PublicKey(pair)).then((res) => {
      if (mounted) {
        setData(res);
      }
    });

    return () => {
      mounted = false;
    };
  }, [pair, tokenSwap]);

  return data;
};
