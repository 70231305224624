import React from 'react';
import styled from 'styled-components';
import { ExternalLinks } from '../../utils/constants';
import { screenUp } from '../../utils/styles';
import { Dropdown, DropdownMenu, DropdownToggle } from '../Dropdown';
import { ReactComponent as IconDocument } from '../../assets/icons/document.svg';
import { ReactComponent as IconDiscord } from '../../assets/icons/discord.svg';
import { ReactComponent as IconGithub } from '../../assets/icons/github.svg';
import { ReactComponent as IconMedium } from '../../assets/icons/medium.svg';
import { ReactComponent as IconTelegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as IconTwitter } from '../../assets/icons/twitter.svg';

const ButtonMore: React.FC = () => {
  return (
    <Dropdown>
      <DropdownToggle>
        <StyledButtonMore>
          More
          <i className="fas fa-sort-down"></i>
        </StyledButtonMore>
      </DropdownToggle>
      <StyledDropdownMenu position="left">
        <ul>
          <li>
            <a href={ExternalLinks.documentations} target="_blank" rel="noreferrer noopener">
              <IconDocument className="icon" />
              <span>Documentations</span>
            </a>
          </li>
          <li>
            <a href={ExternalLinks.codes} target="_blank" rel="noreferrer noopener">
              <IconGithub className="icon" />
              <span>Codes</span>
            </a>
          </li>
          <li>
            <a href={ExternalLinks.medium} target="_blank" rel="noreferrer noopener">
              <IconMedium className="icon" />
              <span>Medium</span>
            </a>
          </li>
          <li>
            <a href={ExternalLinks.twitter} target="_blank" rel="noreferrer noopener">
              <IconTwitter className="icon" />
              <span>Twitter</span>
            </a>
          </li>
          <li>
            <a href={ExternalLinks.telegram} target="_blank" rel="noreferrer noopener">
              <IconTelegram className="icon" />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a href={ExternalLinks.discord} target="_blank" rel="noreferrer noopener">
              <IconDiscord className="icon" />
              <span>Discord</span>
            </a>
          </li>
        </ul>
      </StyledDropdownMenu>
    </Dropdown>
  );
};

const StyledButtonMore = styled.li`
  display: none;
  cursor: pointer;
  margin: 0 18px;
  color: #d6e6dd;
  i {
    padding-left: 4px;
    padding-bottom: 8px;
  }
  &:hover {
    color: #fff;
  }
  ${screenUp('lg')`
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-top: 0px;
  width: 200px;
  min-width: auto;
  ::after {
    content: '';
    background: none;
  }
  ul {
    padding-left: 0;
    margin: 0;
    li {
      padding: 10px 0;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      a {
        color: #16182c;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out 0s;
        span {
          margin-left: 10px;
        }
        .icon {
          width: 18px;
          height: 18px;
          color: #318e8b;
        }
        &:hover {
          color: #318e8b;
        }
      }
    }
  }
`;

export default ButtonMore;
