import { useWallet } from '@solana/wallet-adapter-react';
import { Modal, ModalBody, ModalClose, ModalHeader, ModalTitle } from '../../providers/Modals';
import { WalletName } from '@solana/wallet-adapter-wallets';
import { useCallback } from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/logo-short.png';
import imgFantom from '../../assets/wallets/phantom.png';
import imgSolflare from '../../assets/wallets/solflare.png';
import imgSollet from '../../assets/wallets/sollet.png';
import imgMathwallet from '../../assets/wallets/mathwallet.png';
// import imgSafepal from '../../assets/wallets/safepal.png';
// import imgCoin98 from '../../assets/wallets/coin98.png';
// import imgLedger from '../../assets/wallets/ledger.png';

export type ModalSelectWalletProps = {
  onDismiss?: () => void;
};

const ModalSelectWallet: React.FC<ModalSelectWalletProps> = ({ onDismiss }) => {
  const { connect, select } = useWallet();

  const connectWallet = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const wallet = ev.currentTarget.dataset.wallet as WalletName;
      select(wallet);
      connect()
        .then(() => {
          onDismiss();
        })
        .catch((e) => {
          // toto show error message
          console.log('e', e);
        });
      onDismiss();
    },
    [connect, onDismiss, select],
  );

  return (
    <StyledModal size="sm">
      <StyledHeader>
        <img src={logo} alt="cSpac"/>
        <ModalTitle>Connect wallet</ModalTitle>
        <ModalClose onClick={onDismiss} />
      </StyledHeader>
      <StyledBody>
        <StyledItem data-wallet={WalletName.Phantom} onClick={connectWallet}>
          <img src={imgFantom} alt="phantom"/>
          Phantom
        </StyledItem>
        <StyledItem data-wallet={WalletName.Solflare} onClick={connectWallet}>
          <img src={imgSolflare} alt="Solflare"/>
          Solflare
        </StyledItem>
        <StyledItem data-wallet={WalletName.SolflareWeb} onClick={connectWallet}>
          <img src={imgSolflare} alt="Solflare web"/>
          SolflareWeb
        </StyledItem>
        <StyledItem data-wallet={WalletName.Sollet} onClick={connectWallet}>
          <img src={imgSollet} alt="Sollet"/>
          Sollet
        </StyledItem>
        <StyledItem data-wallet={WalletName.SolletExtension} onClick={connectWallet}>
          <img src={imgSollet} alt="Sollet extension"/>
          SolletExtension
        </StyledItem>
        <StyledItem data-wallet={WalletName.MathWallet} onClick={connectWallet}>
          <img src={imgMathwallet} alt="MathWallet"/>
          MathWallet
        </StyledItem>
        {/* <StyledItem data-wallet={WalletName.Coin98} onClick={connectWallet}>
          <img src={imgCoin98} />
          Coin98
        </StyledItem> */}
        {/* <StyledItem data-wallet={WalletName.SafePal} onClick={connectWallet}>
          <img src={imgSafepal} />
          SafePal
        </StyledItem>
        <StyledItem data-wallet={WalletName.Ledger} onClick={connectWallet}>
          <img src={imgLedger} />
          Ledger
        </StyledItem> */}
      </StyledBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 0;
`;

const StyledHeader = styled(ModalHeader)`
  img {
    width: 36px;
    margin-right: 14px;
  }
`;

const StyledBody = styled(ModalBody)`
  padding: 0;
`;

const StyledItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  color: #16182c;
  padding: 20px 28px;
  img {
    width: 30px;
    margin-right: 24px;
  }
  :hover {
    background-color: #e8e8e8;
    color: #318e8b;
  }
`;

export default ModalSelectWallet;
