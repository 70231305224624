import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { TokenInput } from './TokenInput';
import { screenUp } from '../../utils/styles';
import { BN } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import { Zero } from '../../utils/bignumber';

type Size = 'md' | 'lg';

const FontSize = {
  md: '32px',
  lg: '36px',
};

const BorderRadius = {
  md: '0px',
  lg: '0px',
};

const Padding = {
  md: '0',
  lg: '0',
};

const ButtonMaxPadding = {
  md: '0px 12px;',
  lg: '0px 14px',
};

const ButtonMaxHeight = {
  md: '28px',
  lg: '32px',
};

export type TokenInputWithMaxButtonProps = {
  value: BN | undefined;
  maxValue?: BN;
  maxValidateValue?: BN;
  decimals: number;
  symbol?: string;
  onChange?: (v: BN) => void;
  size?: Size;
  width?: string;
  disabled?: boolean;
  buttonMaxTitle?: string;
  buttonMaxWidth?: string;
  skipCheckZero?: boolean;
  inValid?: boolean;
  hideMaxButton?: boolean;
  background?: string;
};

export const TokenInputWithMaxButton: React.FC<TokenInputWithMaxButtonProps> = ({
  value,
  maxValue,
  maxValidateValue,
  decimals,
  onChange,
  disabled,
  size = 'md',
  buttonMaxTitle,
  buttonMaxWidth,
  skipCheckZero,
  inValid,
  hideMaxButton,
  background,
}) => {
  const { publicKey } = useWallet();
  const [focus, setFocus] = useState(false);

  const isShowMaxButton = useMemo(() => {
    return (
      !hideMaxButton &&
      !disabled &&
      publicKey &&
      !value &&
      (skipCheckZero || maxValue?.gt(Zero))
    );
  }, [disabled, hideMaxButton, maxValue, publicKey, skipCheckZero, value]);

  const isInvalid = useMemo(() => {
    if (disabled) return false;
    if (inValid) {
      return true;
    }
    const max = maxValidateValue ? maxValidateValue : maxValue;
    return value && max && value.gt(max);
  }, [disabled, inValid, maxValidateValue, maxValue, value]);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const setMax = useCallback(() => {
    if (!onChange) return;
    onChange(maxValue);
  }, [maxValue, onChange]);

  return (
    <TokenInputContainer
      focused={focus}
      invalid={isInvalid}
      disabled={disabled}
      size={size}
      buttonMaxWidth={buttonMaxWidth}
      background={background}
    >
      {isShowMaxButton ? <button onClick={setMax}>{buttonMaxTitle || 'MAX'}</button> : null}
      <TokenInput
        decimals={decimals}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </TokenInputContainer>
  );
};

const TokenInputContainer = styled.div<{
  focused: boolean;
  invalid: boolean;
  disabled?: boolean;
  size?: Size;
  buttonMaxWidth?: string;
  background?: string;
}>`
  display: flex;
  width: 100%;
  padding: ${(p) => Padding[p.size || 'lg']};
  border-radius: ${(p) => BorderRadius[p.size || 'lg']};
  background-color: ${({ background }) => background || 'transparent'};
  input {
    height: ${(p) => FontSize[p.size || 'lg']};
    width: 100%;
    background: transparent;
    text-align: right;
    border: none;
    font-size: ${(p) => FontSize[p.size || 'lg']};
    font-weight: bold;
    color: ${({ invalid, disabled }) =>
      !invalid ? (disabled ? '#318e8b' : '#16182c') : '#ff6565'};
    line-height: 1;
    :disabled {
      color: #a2a49d;
    }
    ::placeholder {
      color: #a2a49d;
    }
  }
  button {
    align-self: center;
    margin-right: 4px;
    padding: ${(p) => ButtonMaxPadding[p.size || 'lg']};
    height: ${(p) => ButtonMaxHeight[p.size || 'lg']};
    font-size: 12px;
    font-weight: bold;
    color: #318e8b;
    border: 1px solid #318e8b;
    border-radius: 1000px;
    transition: all 0.2s ease-in-out 0s;
    :hover {
      background-color: #318e8b;
      color: #fff;
    }
    width: ${({ buttonMaxWidth }) => buttonMaxWidth || 'fit-content'};
  }
  ${screenUp('lg')`
    button {
      font-size: 12px;
    }
  `}
`;
