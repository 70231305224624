import { BN, Program, Provider, web3 } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import StableCoinIdl from '../idl/stable-coin.json';
import OracleIdl from '../idl/twap-oracle.json';
import { PoolAccount, PriceAccount } from './accounts';
import { ORACLE_PROGRAM_ID, STABLE_COIN_PROGRAM_ID } from './constants';

export class PoolManager {
  private program: Program;
  private address: PublicKey;
  private oracle: Program;

  constructor(address: string, private provider: Provider) {
    this.address = new PublicKey(address);
    this.program = new Program(StableCoinIdl as any, STABLE_COIN_PROGRAM_ID, provider);
    this.oracle = new Program(OracleIdl as any, ORACLE_PROGRAM_ID, provider);
  }

  async oracleInfo(price: PublicKey) {
    if (!price) {
      return;
    }
    const data = (await this.oracle.account.price.fetch(price)) as PriceAccount;
    return data;
  }

  async refreshCollateralRatio(pool: PublicKey) {
    const info = (await this.program.account.pool.fetch(pool)) as PoolAccount;

    return await this.program.rpc.refreshCollateralRatio({
      accounts: {
        dollarPrice: info.dollarPrice,
        pool: pool,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        dollar: info.dollar,
        poolVault: info.vault,
      },
    });
  }

  async toggleEcr(pool: PublicKey) {
    const info = (await this.program.account.pool.fetch(pool)) as PoolAccount;
    const isEnabled = info.collateralRatio.enableEcr;

    return await this.program.rpc.setEnableEcr(!isEnabled, {
      accounts: {
        pool: pool,
        admin: this.provider.wallet.publicKey,
      },
    });
  }

  async setRefreshCRCooldown(pool: PublicKey, time: number) {
    return await this.program.rpc.setRefreshCollateralRatioPeriod(new BN(time), {
      accounts: {
        pool: pool,
        admin: this.provider.wallet.publicKey,
      },
    });
  }

  async updateOracle(price: PublicKey) {
    const data = await this.oracleInfo(price);
    return await this.oracle.rpc.update({
      accounts: {
        price: price,
        tokenAAccount: data.tokenAAccount,
        tokenBAccount: data.tokenBAccount,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
      },
    });
  }
}
