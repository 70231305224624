import { Provider } from '@project-serum/anchor';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { createContext, useContext, useMemo } from 'react';

const ProviderContext = createContext<Provider>(null);

const Web3Provider: React.FC = ({ children }) => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const provider = useMemo(() => {
    return new Provider(connection, wallet, {
      preflightCommitment: 'processed',
    });
  }, [connection, wallet]);

  return <ProviderContext.Provider value={provider}>{children}</ProviderContext.Provider>;
};

export default Web3Provider;

export const useProvider = () => {
  const context = useContext(ProviderContext);
  if (!context) {
    throw new Error('Web3 Provider not provided');
  }

  return context;
};
