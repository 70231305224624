import { BN, Provider } from '@project-serum/anchor';
import {
  createTokenAccountInstrs,
  getMintInfo,
  getTokenAccount,
} from '@project-serum/common';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  TokenSwapLayout,
  TokenSwap as TokenSwapFacade,
  TOKEN_SWAP_PROGRAM_ID,
} from '@solana/spl-token-swap';
import { Keypair, PublicKey, Transaction } from '@solana/web3.js';
import { Precision } from '../utils/bignumber';
import BufferLayout from '@solana/buffer-layout';

export class TokenSwap {
  constructor(private provider: Provider) {}

  async info(pair: PublicKey) {
    const account = await this.provider.connection.getAccountInfo(pair);
    const data = TokenSwapLayout.decode(account.data);

    const poolMint = new PublicKey(data.tokenPool);
    const tokenAccountA = new PublicKey(data.tokenAccountA);
    const tokenAccountB = new PublicKey(data.tokenAccountB);
    const [{ mintAuthority: authority, supply }, reserveA, reserveB] =
      await Promise.all([
        getMintInfo(this.provider, poolMint),
        getTokenAccount(this.provider, tokenAccountA).then((x) => x.amount),
        getTokenAccount(this.provider, tokenAccountB).then((x) => x.amount),
      ]);

    return {
      authority,
      feeAccount: new PublicKey(data.feeAccount),
      hostFeeAccount: new PublicKey(data.feeAccount),
      poolMint: new PublicKey(data.tokenPool),
      tokenAccountA,
      tokenAccountB,
      tokenA: new PublicKey(data.mintA),
      tokenB: new PublicKey(data.mintB),
      lpSupply: supply,
      reserveA,
      reserveB,
    };
  }

  estimate(
    input: PublicKey,
    amount: BN,
    slippage: BN,
    poolInfo: any,
  ) {
    const { reserveA, reserveB, tokenA } = poolInfo;

    const inputAmountPostSwap = input.equals(tokenA)
      ? reserveA.add(amount)
      : reserveB.add(amount);
    const outputAmountPreSwap = input.equals(tokenA) ? reserveB : reserveA;
    const outputAmountPostSwap = reserveA
      .mul(reserveB)
      .div(inputAmountPostSwap);
    return outputAmountPreSwap
      .sub(outputAmountPostSwap)
      .mul(Precision.sub(slippage))
      .div(Precision);
  }

  async swap(
    pair: PublicKey,
    user: PublicKey,
    userInput: PublicKey,
    userOutput: PublicKey,
    inputToken: PublicKey,
    amountIn: BN,
    minAmountOut: BN,
  ) {
    const {
      authority,
      feeAccount,
      hostFeeAccount,
      poolMint,
      tokenAccountA,
      tokenAccountB,
      tokenA,
      tokenB,
    } = await this.info(pair);
    const transaction = new Transaction();
    const signers = [];
    const inputIsTokenA = inputToken.equals(tokenA);

    if (!userOutput) {
      const key = new Keypair();
      const output = inputIsTokenA ? tokenB : tokenA;
      const initAccountIx = Token.createInitAccountInstruction(
        TOKEN_PROGRAM_ID,
        output,
        key.publicKey,
        user,
      );
      transaction.add(initAccountIx);
      signers.push(key);
    }

    const transferAuthorityKeyPair = Keypair.generate();
    signers.push(transferAuthorityKeyPair);
    const transferAuthority = transferAuthorityKeyPair.publicKey;
    transaction.add(
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        userInput,
        transferAuthority,
        user,
        [],
        amountIn.toNumber(),
      ),
    );

    transaction.add(
      TokenSwapFacade.swapInstruction(
        pair,
        authority,
        transferAuthority,
        userInput,
        /* poolSource: */ inputIsTokenA ? tokenAccountA : tokenAccountB,
        /* poolDestination: */ inputIsTokenA ? tokenAccountB : tokenAccountA,
        userOutput,
        poolMint,
        feeAccount,
        hostFeeAccount,
        TOKEN_SWAP_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        amountIn.toNumber(),
        minAmountOut.toNumber(),
      ),
    );

    transaction.add(
      Token.createRevokeInstruction(TOKEN_PROGRAM_ID, userInput, user, []),
    );

    return await this.provider.send(transaction, signers);
  }

  estimateAddLiquidity(
    swap: PublicKey,
    amountA: BN,
    amountB: BN,
    slippage: BN,
    lpSupply: BN,
    reserveA: BN,
    reserveB: BN,
  ) {
    return BN.min(
      amountA
        .mul(Precision.sub(slippage))
        .mul(lpSupply)
        .div(reserveA)
        .div(Precision),
      amountB
        .mul(Precision.sub(slippage))
        .mul(lpSupply)
        .div(reserveB)
        .div(Precision),
    );
  }

  async addLiquidity(
    swap: PublicKey,
    user: PublicKey,
    sourceA: PublicKey,
    sourceB: PublicKey,
    tokenAAmount: BN,
    tokenBAmount: BN,
    estimatedOutput: BN,
    userLpAccount: PublicKey,
  ) {
    const info = await this.info(swap);
    const transaction = new Transaction();
    const signers: Keypair[] = [];

    if (!userLpAccount) {
      const key = Keypair.generate();
      transaction.add(
        ...(await createTokenAccountInstrs(
          this.provider,
          key.publicKey,
          info.poolMint,
          user,
        )),
      );
      signers.push(key);
      userLpAccount = key.publicKey;
    }

    const transferAuthorityKeyPair = Keypair.generate();
    signers.push(transferAuthorityKeyPair);
    const transferAuthority = transferAuthorityKeyPair.publicKey;
    transaction.add(
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        sourceA,
        transferAuthority,
        user,
        [],
        tokenAAmount.toNumber(),
      ),
    );

    transaction.add(
      Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        sourceB,
        transferAuthority,
        user,
        [],
        tokenBAmount.toNumber(),
      ),
    );

    transaction.add(
      TokenSwapFacade.depositAllTokenTypesInstruction(
        swap,
        info.authority,
        transferAuthority,
        sourceA,
        sourceB,
        info.tokenAccountA,
        info.tokenAccountB,
        info.poolMint,
        userLpAccount,
        TOKEN_SWAP_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        estimatedOutput.toNumber(),
        // 1e10,
        tokenAAmount.toNumber(),
        tokenBAmount.toNumber(),
      ),
    );

    transaction.add(
      Token.createRevokeInstruction(TOKEN_PROGRAM_ID, sourceA, user, []),
    );
    transaction.add(
      Token.createRevokeInstruction(TOKEN_PROGRAM_ID, sourceB, user, []),
    );

    return await this.provider.send(transaction, signers);
  }
}

export const uint64 = (property: string = 'uint64'): BufferLayout.Blob => {
  return BufferLayout.blob(8, property);
};
