import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOracle } from '../../providers/ChainProvider';
import { useLastUpdate } from '../application/hooks';
import { multipleBalanceFetched, tokenPriceUpdated } from './actions';

const Updater: React.FC = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const dispatch = useDispatch();
  const lastUpdate = useLastUpdate();

  useEffect(() => {
    if (!connection || !wallet.connected) {
      return;
    }

    connection
      .getParsedTokenAccountsByOwner(
        wallet.publicKey,
        {
          programId: TOKEN_PROGRAM_ID,
        },
        'confirmed',
      )
      .then((res) => {
        return res.value.map((t) => {
          const parsed = t.account.data.parsed.info;
          return {
            address: parsed.mint,
            balance: parsed.tokenAmount.amount,
            decimals: parsed.tokenAmount.decimals,
            tokenAccountAddress: t.pubkey.toString(),
          };
        });
      })
      .then((list) => {
        dispatch(multipleBalanceFetched(list));
      });
  }, [connection, dispatch, wallet.connected, wallet.publicKey, lastUpdate]);

  const oracle = useOracle();
  useEffect(() => {
    if (!oracle) {
      return;
    }

    Promise.all([oracle.sharePrice(), oracle.dollarPrice()]).then(
      ([sharePrice, dollarPrice]) => {
        dispatch(
          tokenPriceUpdated({ share: sharePrice.toString(), dollar: dollarPrice.toString() }),
        );
      },
    );
  }, [lastUpdate, oracle, dispatch]);

  return null;
};

export default Updater;
