import { createAction } from '@reduxjs/toolkit';
import { PopupContent } from './reducer';

export const login = createAction<string>('application/login');

export const logout = createAction('application/logout');

export const requestUpdate = createAction('application/update');

export const toggleMainNav =
  createAction<{ isOpen: boolean | null | undefined }>('app/toggleMainNav');

export const setSlippageTolerance = createAction<{ slippage: number }>(
  'app/setSlippageTolerance',
);

export const addPopup = createAction<{
  key?: string;
  removeAfterMs?: number | null;
  content: PopupContent;
}>('app/addPopup');

export const removePopup = createAction<{ key: string }>('app/removePopup');
