import { BN, Program, Provider } from '@project-serum/anchor';
import { createTokenAccountInstrs, getTokenAccount } from '@project-serum/common';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  Transaction,
} from '@solana/web3.js';
import { sum, Zero } from '../utils/bignumber';
import { ChefInfo, StakeInfo } from './accounts';
import ChefIDL from '../idl/chef.json';
import { CHEF_PROGRAM_ID } from './constants';

export class Chef {
  private chefAccount: PublicKey;
  private program: Program;

  constructor(address: string, private provider: Provider) {
    this.program = new Program(ChefIDL as any, CHEF_PROGRAM_ID, provider);
    this.chefAccount = new PublicKey(address);
  }

  async info() {
    const chefInfo = (await this.program.account.chefInfo.fetch(this.chefAccount)) as ChefInfo;
    const totalAllocPoint = chefInfo.pools.map((t) => t.allocPoint).reduce(sum, Zero);

    const poolInfos = await Promise.all(
      chefInfo.pools.map(async (t, i) => {
        const vault = await getTokenAccount(this.provider, t.vault);

        return {
          id: i,
          totalStaked: vault.amount,
          allocPoint: t.allocPoint,
          vaultAddress: t.vault,
          want: t.wantToken.toString(),
          accRewardPerShare: t.accRewardPerShare.toString(),
          lastRewardTime: t.lastRewardTime.toNumber(),
        };
      }),
    );

    return {
      reward: chefInfo.rewardToken.toString(),
      rewardPerSecond: chefInfo.rewardPerSecond,
      totalAllocPoint,
      poolInfos,
    };
  }

  async userInfo(user: PublicKey) {
    const data = await this.program.account.stakeInfo.all(
      Buffer.concat([this.chefAccount.toBuffer(), user.toBuffer()]),
    );
    return data.map((t) => {
      return {
        ...(t.account as StakeInfo),
        address: t.publicKey.toString(),
      };
    });
  }

  async deposit(
    user: PublicKey,
    poolId: number,
    amount: BN,
    source: PublicKey,
    userStake: PublicKey,
  ) {
    const chefInfo = (await this.program.account.chefInfo.fetch(this.chefAccount)) as ChefInfo;
    let instructions: any[];
    let signers: Keypair[] = [];
    if (!userStake) {
      [instructions, userStake, signers] = this.createStakeAccount(poolId, user);
    }

    return await this.program.rpc.deposit(poolId, amount, {
      accounts: {
        chef: this.chefAccount,
        user: user,
        depositor: source,
        vault: chefInfo.pools[poolId].vault,
        tokenProgram: TOKEN_PROGRAM_ID,
        stake: userStake,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
      signers,
      instructions,
    });
  }

  async harvest(user: PublicKey, poolId: number, userStake: PublicKey) {
    const [chefAuthority] = await PublicKey.findProgramAddress(
      [this.chefAccount.toBuffer()],
      this.program.programId,
    );
    const chefInfo = (await this.program.account.chefInfo.fetch(this.chefAccount)) as ChefInfo;
    const vault = chefInfo.pools[poolId].vault;
    const rewardFund = chefInfo.rewardFund;
    const signers: Keypair[] = [];
    let createRewardTokenAccountIx: any[];
    let userRewardAccount = await this.provider.connection
      .getTokenAccountsByOwner(
        user,
        {
          mint: chefInfo.rewardToken,
        },
        'confirmed',
      )
      .then((x) => x.value.map((t) => t.pubkey)[0]);

    if (!userRewardAccount) {
      const userRewardAccountKey = Keypair.generate();
      createRewardTokenAccountIx = await createTokenAccountInstrs(
        this.provider,
        userRewardAccountKey.publicKey,
        chefInfo.rewardToken,
        this.provider.wallet.publicKey,
      );
      signers.push(userRewardAccountKey);
      userRewardAccount = userRewardAccountKey.publicKey;
    }

    return await this.program.rpc.harvest(poolId, {
      accounts: {
        chef: this.chefAccount,
        user,
        vault,
        rewardFund,
        chefAuthority,
        harvestTo: userRewardAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        stake: userStake,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
      instructions: createRewardTokenAccountIx,
      signers,
    });
  }

  async withdrawAndHarvest(
    user: PublicKey,
    poolId: number,
    amount: BN,
    destination: PublicKey,
    userStake: PublicKey,
  ) {
    const [chefAuthority] = await PublicKey.findProgramAddress(
      [this.chefAccount.toBuffer()],
      this.program.programId,
    );
    const chefInfo = (await this.program.account.chefInfo.fetch(this.chefAccount)) as ChefInfo;
    const vault = chefInfo.pools[poolId].vault;
    const rewardFund = chefInfo.rewardFund;
    const signers: Keypair[] = [];

    let userRewardAccount = await this.provider.connection
      .getTokenAccountsByOwner(
        user,
        {
          mint: chefInfo.rewardToken,
        },
        'confirmed',
      )
      .then((x) => x.value.map((t) => t.pubkey)[0]);

    const transaction = new Transaction();

    if (!userRewardAccount) {
      const userRewardAccountKey = Keypair.generate();
      const createRewardTokenAccountIx = await createTokenAccountInstrs(
        this.provider,
        userRewardAccountKey.publicKey,
        chefInfo.rewardToken,
        this.provider.wallet.publicKey,
      );
      transaction.add(...createRewardTokenAccountIx);
      signers.push(userRewardAccountKey);
      userRewardAccount = userRewardAccountKey.publicKey;
    }

    const harvestIx = this.program.instruction.harvest(poolId, {
      accounts: {
        chef: this.chefAccount,
        user,
        vault,
        rewardFund,
        chefAuthority,
        harvestTo: userRewardAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        stake: userStake,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });

    const withdrawIx = this.program.instruction.withdraw(poolId, amount, {
      accounts: {
        chef: this.chefAccount,
        user,
        withdrawTo: destination,
        vault,
        chefAuthority,
        tokenProgram: TOKEN_PROGRAM_ID,
        stake: userStake,
        clock: SYSVAR_CLOCK_PUBKEY,
        rewardFund: chefInfo.rewardFund,
      },
      instructions: [harvestIx],
    });

    transaction.add(harvestIx);
    transaction.add(withdrawIx);
    return await this.provider.send(transaction, signers);
  }

  private createStakeAccount(poolId: number, user: PublicKey): [any[], PublicKey, [Keypair]] {
    const userStake = Keypair.generate();
    const instruction = this.program.instruction.createStakeAccount(poolId, {
      accounts: {
        user,
        chef: this.chefAccount,
        stake: userStake.publicKey,
        systemProgram: SystemProgram.programId,
      },
      signers: [userStake],
    });

    return [[instruction], userStake.publicKey, [userStake]];
  }
}
