import styled from 'styled-components';

export type Size = 'sm' | 'md' | 'lg';

const Heights: Record<Size, number> = {
  sm: 32,
  md: 46,
  lg: 58,
};

const BorderRadius = {
  sm: '1000px',
  md: '1000px',
  lg: '1000px',
};

const FontSize = {
  sm: '16px',
  md: '20px',
  lg: '24px',
};

const FontWeight = {
  sm: 'bold',
  md: 'bold',
  lg: 'bold',
};

export const ButtonOutline = styled.button<{
  error?: boolean;
  size?: Size;
  block?: boolean;
}>`
  position: relative;
  z-index: 1;
  padding: 5px 20px;
  font-size: ${(p) => FontSize[p.size || 'lg']};
  border-radius: ${(p) => BorderRadius[p.size || 'lg']};
  font-weight: ${(p) => FontWeight[p.size || 'lg']};
  color: #318e8b;
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  height: ${(p) => Heights[p.size || 'lg']}px;
  border: solid 1px #318e8b;
  i,
  img {
    margin-right: 8px;
    margin-bottom: 1px;
  }
  :not(:disabled) {
    :hover {
      background-color: #318e8b;
      color: #ffffff;
    }
  }
  :disabled {
    pointer-events: none;
    color: #757576;
    border: 1px solid ${({ error }) => (error ? '#ff6565' : '#dbd8d4')};
  }
`;

export const Button = styled.button<{
  error?: boolean;
  size?: Size;
  block?: boolean;
}>`
  padding: 0 16px;
  font-size: ${(p) => FontSize[p.size || 'lg']};
  border-radius: ${(p) => BorderRadius[p.size || 'lg']};
  font-weight: ${(p) => FontWeight[p.size || 'lg']};
  color: #ffffff;
  line-height: 1;
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  height: ${(p) => Heights[p.size || 'lg']}px;
  width: ${(p) => (p.block ? '100%' : '')};
  background-color: #318e8b;
  i,
  img {
    margin-right: 8px;
    margin-bottom: 1px;
  }
  :not(:disabled) {
    :hover {
      background-color: #2e7674;
    }
  }
  :disabled {
    pointer-events: none;
    color: ${({ error }) => (error ? '#ff6565' : '#757576')};
    background: #e3d2d2;
  }
`;
