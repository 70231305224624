import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ExplorerLink } from '../ExplorerLink';
import { useSelector } from 'react-redux';
import { AppState } from '../../state';
import { TransactionSelectors } from '../../state/transaction/hooks';
import { ReactComponent as IconError} from '../../assets/icons/minus-circle.svg';
import { ReactComponent as IconSuccess} from '../../assets/icons/check-mark.svg';
import { ReactComponent as IconWaiting} from '../../assets/icons/waiting.svg';

export type TransactionPopupProps = {
  hash: string;
};

export const TransactionPopup: React.FC<TransactionPopupProps> = ({ hash }) => {
  const transaction = useSelector((s: AppState) => TransactionSelectors.selectById(s, hash));

  const status = useMemo(() => {
    if (!transaction) {
      return 'error';
    }
    if (transaction && !transaction.confirmed) {
      return 'submitted';
    }

    if (!transaction.error) {
      // add lending error report logic
      return 'success';
    }

    return 'error';
  }, [transaction]);

  return (
    <Container status={status}>
      <StyledIcon>
        {status === 'success' ? (
          <IconSuccess />
        ) : status === 'submitted' ? (
          <IconWaiting />
        ) : (
          <IconError />
        )}
      </StyledIcon>
      <div>
        {status === 'submitted' ? (
          <Title>Transaction is pending</Title>
        ) : status === 'success' ? (
          <Title>Transaction completed</Title>
        ) : (
          <Title>Transaction failed</Title>
        )}
        <div>
          {transaction?.summary}
          <ViewTransaction>
            <ExplorerLink type="tx" address={transaction?.hash}>
              View transaction
            </ExplorerLink>
          </ViewTransaction>
        </div>
      </div>
    </Container>
  );
};

const Title = styled.div`
  font-weight: 700;
`;

const Container = styled.div<{ status: 'error' | 'submitted' | 'success' }>`
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #d34242;
    border-radius: 5px 0 0 5px;
    background: ${(p) =>
      p.status === 'error' ? '#d34242' : p.status === 'success' ? '#318e8b' : '#ff8e35'};
  }

  ${Title} {
    color: ${(p) =>
      p.status === 'error' ? '#d34242' : p.status === 'success' ? '#318e8b' : '#ff8e35'};
  }
`;

const StyledIcon = styled.div`
  margin-right: 15px;
  flex-shrink: 0;
  font-size: 1.2rem;
`;

const ViewTransaction = styled.div`
  a {
    color: #15d0e4;
    font-size: 13px;
  }
`;
