import BigNumber from 'bn.js';
import React, { useMemo } from 'react';
import { formatUnits } from '../utils/bignumber';

export type FormatOption = {
  locale: string;
  compact: boolean;
  fractionDigits: number;
  keepTrailingZeros: boolean;
  significantDigits?: number;
  percentage?: boolean;
  currency?: string;
  thousandGrouping: boolean;
};

const parseConfig = (fmt: FormatOption): Intl.NumberFormatOptions => {
  const ret: Intl.NumberFormatOptions = {
    maximumFractionDigits: fmt.fractionDigits,
  };

  if (fmt.compact) {
    ret.notation = 'compact';
  }
  if (fmt.keepTrailingZeros) {
    ret.minimumFractionDigits = fmt.fractionDigits;
  }

  if (fmt.significantDigits) {
    ret.minimumSignificantDigits = fmt.significantDigits;
    ret.maximumFractionDigits = null;
  }

  if (fmt.percentage) {
    ret.style = 'percent';
  }

  if (fmt.currency) {
    ret.style = 'currency';
    ret.currency = fmt.currency;
    ret.minimumFractionDigits = 0;
  }

  if (fmt.thousandGrouping) {
    ret.useGrouping = true;
  }

  return ret;
};

const defaultFormat: FormatOption = {
  locale: 'en-US',
  compact: true,
  keepTrailingZeros: false,
  fractionDigits: 3,
  percentage: false,
  currency: '',
  thousandGrouping: true,
};

const defaultFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 3,
  useGrouping: true,
});

export const formatNumber = (input: number, options?: Partial<FormatOption>): string => {
  if (input == null || isNaN(input)) {
    return '';
  }
  let formatter: Intl.NumberFormat;
  if (!options) {
    formatter = defaultFormatter;
  } else {
    formatter = new Intl.NumberFormat(
      'en-US',
      parseConfig({
        ...defaultFormat,
        ...options,
      }),
    );
  }
  return formatter.format(input);
};

export const formatBigNumber = (
  input: BigNumber,
  decimals: number,
  option?: Partial<FormatOption>,
  threshold?: number,
): string => {
  if (!input) {
    return '-';
  }
  const value = +formatUnits(input, decimals);
  if (threshold && value > 0 && value < threshold) {
    return `< ${formatNumber(threshold, {
      ...option,
      significantDigits: 1,
    })}`;
  }
  return formatNumber(value, option);
};

export type BigNumberValueProps = {
  value: BigNumber | undefined;
  decimals: number;
  fractionDigits?: number;
  keepTrailingZeros?: boolean;
  keepCommas?: boolean;
  percentage?: boolean;
  compact?: boolean;
  emptyValue?: string;
  split?: boolean;
  threshold?: number;
  currency?: string;
};

export const BigNumberValue: React.FC<BigNumberValueProps> = ({
  value,
  decimals,
  fractionDigits,
  keepTrailingZeros,
  keepCommas,
  percentage,
  compact,
  emptyValue,
  split,
  threshold,
  currency,
}) => {
  const text = useMemo(() => {
    return value
      ? formatBigNumber(
          value,
          decimals,
          {
            fractionDigits,
            keepTrailingZeros,
            percentage,
            thousandGrouping: keepCommas,
            compact,
            currency,
          },
          threshold,
        )
      : emptyValue || '-';
  }, [
    compact,
    currency,
    decimals,
    emptyValue,
    fractionDigits,
    keepCommas,
    keepTrailingZeros,
    percentage,
    threshold,
    value,
  ]);

  const renderText = useMemo(() => {
    if (!split || !text) {
      return text;
    }
    const reg = /[d+.,]/;
    if (!reg.test(text)) {
      return text;
    }
    if (text.indexOf('.') === -1) {
      return text;
    }
    const pre = text.substring(0, text.indexOf('.'));
    const suf = text.substring(text.indexOf('.'), text.length);
    return (
      <>
        <span className="num_prefix">{pre}</span>
        <span className="num_suffix">{suf}</span>
      </>
    );
  }, [split, text]);

  return <>{renderText}</>;
};
