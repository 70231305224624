import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { transactionConfirmed, transcationSubmitted } from './actions';

export type Transaction = {
  hash: string;
  summary: string;
  extras?: {};
  error?: string;
  confirmed?: boolean;
  popupId: string;
};

export const adapter = createEntityAdapter<Transaction>({
  selectId: (x) => x.hash,
});

const initialState = adapter.getInitialState();

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(transcationSubmitted, (state, { payload }) => {
      return adapter.addOne(state, { ...payload, confirmed: false });
    })
    .addCase(transactionConfirmed, (state, { payload }) => {
      return adapter.updateOne(state, {
        changes: { ...payload, confirmed: true },
        id: payload.hash,
      });
    });
});

export default reducer;
