import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import { multipleBalanceFetched, tokenPriceUpdated } from './actions';

export type Token = {
  address: string;
  balance?: string;
  tokenAccountAddress?: string;
};

type TokenState = {
  watching: string[];
  list: EntityState<Token>;
  price: {
    share: string;
    dollar: string;
  };
};

export const tokenAdapter = createEntityAdapter<Token>({
  selectId: (t) => t.address,
});

const initialState = {
  watching: [],
  list: tokenAdapter.getInitialState(),
} as TokenState;

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(multipleBalanceFetched, (state, action) => {
    tokenAdapter.setMany(state.list, action.payload);
  });

  builder.addCase(tokenPriceUpdated, (state, { payload }) => {
    state.price = payload;
  });
});

export default reducer;
