import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon} from '../../assets/icons/minus-circle.svg';

export type ErrorPopupProps = {
  title: string;
  message: string;
};

export const ErrorPopup: React.FC<ErrorPopupProps> = ({ title, message }) => {
  return (
    <Container>
      <StyledIcon>
        <i className="fa fa-exclamation-circle"></i>
      </StyledIcon>
      <div>
        <Title>{title}</Title>
        <div>{message}</div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #d34242;
    border-radius: 5px 0 0 5px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  color: #d34242;
`;

const StyledIcon = styled.div.attrs({
  children: <Icon />,
})`
  margin-right: 10px;
  flex-shrink: 0;
`;
