import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import React, { ReactNode, ReactText, useMemo } from 'react';
import config from '../config';

export type ExplorerLinkProps = {
  address: string | number;
  children: ReactNode | ReactText;
  type?: 'account' | 'tx' | 'block' | 'address';
};

export const ExplorerLink: React.FC<ExplorerLinkProps> = ({ address, children, type }) => {
  const url = useMemo(() => {
    if (config.network === WalletAdapterNetwork.Devnet) {
      return `${['https://explorer.solana.com', type || 'address', address].join(
        '/',
      )}?cluster=${config.network}`;
    }
    return ['https://solscan.io', type || 'address', address].join('/');
  }, [address, type]);

  return (
    <a target="_blank" href={url} rel="noreferrer" >
      {children}
    </a>
  );
};
