import { createAction } from '@reduxjs/toolkit';

export const multipleBalanceFetched = createAction<
  Array<{ address: string; balance: string; tokenAccountAddress: string }>
>('token/multipleBalanceFetched');

export const tokenPriceUpdated = createAction<{
  share: string;
  dollar: string;
}>('token/priceUpdated');
