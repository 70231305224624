import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import { chain, mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { TokenByAddress } from '../../config/tokens';
import { AppState } from '..';
import { Token, tokenAdapter } from './reducer';

export const TokenSelectors = tokenAdapter.getSelectors((s: AppState) => s.token.list);

export const selectUserTokenAccount = (tokenAddres: string) => {
  return createSelector([(s: AppState) => TokenSelectors.selectById(s, tokenAddres)], (x) => {
    if (x) {
      return {
        ...x,
        tokenAccountAddress: new PublicKey(x.tokenAccountAddress),
        balance: new BN(x.balance),
      };
    }
  });
};

export const selectToken = (t: Token) => {
  if (t) {
    return {
      address: new PublicKey(t.address),
      balance: new BN(t.balance),
      tokenAccount: t.tokenAccountAddress ? new PublicKey(t.tokenAccountAddress) : null,
    };
  }
};

export const selectTokenBalanceBySymbol = createSelector([TokenSelectors.selectAll], (all) => {
  return chain(all)
    .map((t) => {
      return [TokenByAddress[t.address]?.symbol, new BN(t.balance)];
    })
    .filter((t) => !!t[0])
    .fromPairs()
    .value();
});

export const selectTokenAccountBySymbol = createSelector([TokenSelectors.selectAll], (all) => {
  return chain(all)
    .map((t) => {
      return [TokenByAddress[t.address]?.symbol, new PublicKey(t.tokenAccountAddress)];
    })
    .filter((t) => !!t[0])
    .fromPairs()
    .value();
});

export const selectTokenPrice = createSelector([(s: AppState) => s.token.price], (s) =>
  mapValues(s, (t) => new BN(t)),
);
