/* eslint-disable no-throw-literal */
import BN from 'bn.js';

export const Zero = new BN(0);
const NegativeOne = new BN(-1);
export const Precision = new BN(1e6);

type BigNumberish = BN | string | number;

let zeros = '0';

function getMultiplier(decimals: BigNumberish): string {
  if (typeof decimals !== 'number') {
    try {
      decimals = new BN(decimals).toNumber() as any;
    } catch (e) {
      // forgive
    }
  }

  if (typeof decimals === 'number' && decimals >= 0 && decimals <= 256 && !(decimals % 1)) {
    return '1' + zeros.repeat(decimals);
  }
}

export function formatFixed(value: BigNumberish, decimals?: string | BigNumberish): string {
  if (decimals == null) {
    decimals = 0;
  }
  const multiplier = getMultiplier(decimals);

  // Make sure wei is a big number (convert as necessary)
  value = new BN(value);

  const negative = value.lt(Zero);
  if (negative) {
    value = value.mul(NegativeOne);
  }

  let fraction = value.mod(new BN(multiplier)).toString();
  while (fraction.length < multiplier.length - 1) {
    fraction = '0' + fraction;
  }

  // Strip training 0
  fraction = fraction.match(/^([0-9]*[1-9]|0)(0*)/)[1];

  const whole = value.div(new BN(multiplier)).toString();
  if (multiplier.length === 1) {
    value = whole;
  } else {
    value = whole + '.' + fraction;
  }

  if (negative) {
    value = '-' + value;
  }

  return value;
}

export function formatUnits(value: BigNumberish, unitName?: BigNumberish): string {
  // if (typeof(unitName) === "string") {
  //     const index = names.indexOf(unitName);
  //     if (index !== -1) { unitName = 3 * index; }
  // }
  return formatFixed(value, unitName != null ? unitName : 18);
}

export function parseFixed(value: string, decimals?: BigNumberish): BN {
  if (decimals == null) {
    decimals = 0;
  }
  const multiplier = getMultiplier(decimals);

  if (typeof value !== 'string' || !value.match(/^-?[0-9.,]+$/)) {
    throw 'invalid decimal value';
  }

  // Is it negative?
  const negative = value.substring(0, 1) === '-';
  if (negative) {
    value = value.substring(1);
  }

  if (value === '.') {
    throw 'missing value';
  }

  // Split it into a whole and fractional part
  const comps = value.split('.');
  if (comps.length > 2) {
    throw 'too many decimal points';
  }

  let whole = comps[0],
    fraction = comps[1];
  if (!whole) {
    whole = '0';
  }
  if (!fraction) {
    fraction = '0';
  }

  // Get significant digits to check truncation for underflow
  {
    const sigFraction = fraction.replace(/^([0-9]*?)(0*)$/, (all, sig) => sig);
    if (sigFraction.length > multiplier.length - 1) {
      throw 'fractional component exceeds decimals';
    }
  }

  // Fully pad the string with zeros to get to wei
  while (fraction.length < multiplier.length - 1) {
    fraction += '0';
  }

  const wholeValue = new BN(whole);
  const fractionValue = new BN(fraction);

  let wei = wholeValue.mul(new BN(multiplier)).add(fractionValue);

  if (negative) {
    wei = wei.mul(NegativeOne);
  }

  return wei;
}

export const safeParseUnits = (x: string, decimals: number): BN | undefined => {
  if (!x) {
    return;
  }
  try {
    const lastDot = x.lastIndexOf('.');
    if (lastDot >= 0) {
      x = x.substr(0, lastDot + decimals + 1);
    }
    return parseFixed(x, decimals);
  } catch (e) {
    return;
  }
};

export const sum = (a: BN, b: BN) => a.add(b);
