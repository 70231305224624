import { createReducer, nanoid } from '@reduxjs/toolkit';
import {
  login,
  logout,
  requestUpdate,
  toggleMainNav,
  setSlippageTolerance,
  addPopup,
  removePopup,
} from './actions';

export type PopupContent =
  | {
      type: 'error';
      message: string;
      title: string;
    }
  | {
      type: 'waiting';
      title?: string;
      message: string;
    }
  | {
      type: 'transaction';
      hash: string;
    };

type PopupList = Array<{
  key: string;
  show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

type ApplicationState = {
  user?: string;
  lastUpdate: number;
  slippage: number;
  mainNavOpen?: boolean;
  popupList: PopupList;
};

const initialState: ApplicationState = {
  lastUpdate: Date.now(),
  slippage: 0.005,
  popupList: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, { payload }) => {
      state.user = payload;
    })
    .addCase(logout, (state) => {
      state.user = undefined;
    })
    .addCase(requestUpdate, (state) => {
      state.lastUpdate = Date.now();
    })
    .addCase(setSlippageTolerance, (state, { payload: { slippage } }) => {
      state.slippage = slippage;
    })
    .addCase(toggleMainNav, (state, { payload }) => {
      if (payload.isOpen == null) {
        state.mainNavOpen = !state.mainNavOpen;
      } else {
        state.mainNavOpen = payload.isOpen;
      }
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs } }) => {
      state.popupList = (
        key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    });
});

export default reducer;
