import { useWallet } from '@solana/wallet-adapter-react';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useModal from '../../hooks/useModal';
import { shortenAddress } from '../../utils/addresses';
import { Dropdown, DropdownMenu, DropdownToggle } from '../Dropdown';
import ModalAccount from '../ModalAccount';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { ReactComponent as AvatarGrey } from '../../assets/icons/avatar-grey.svg';
import { ReactComponent as IconHistory } from '../../assets/icons/history.svg';
import { ReactComponent as IconDisconnect } from '../../assets/icons/disconnect.svg';
import { ReactComponent as IconExternalLink } from '../../assets/icons/external-link.svg';
import { ExplorerLink } from '../ExplorerLink';

const ButtonAccount: React.FC = () => {
  const { publicKey, disconnect } = useWallet();
  const shortenAccount = shortenAddress(publicKey?.toString() || '');
  const [showAccountModal] = useModal(<ModalAccount />);

  const onDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  return (
    <Dropdown>
      <DropdownToggle>
        <Account>
          <Avatar className="avatar" />
          {shortenAccount}
          <i className="far fa-chevron-down" />
        </Account>
      </DropdownToggle>
      <StyledDropdownMenu position="right">
        <StyledHeader>
          <AvatarGrey className="avatar" />
          {shortenAccount}
          <button>
            <i className="fal fa-clone" />
          </button>
        </StyledHeader>
        <StyledContent>
          <StyledRow>
            <IconExternalLink className="external" />
            <ExplorerLink type="account" address={publicKey?.toString()}>
              View contract
            </ExplorerLink>
          </StyledRow>
          <StyledRow>
            <IconHistory className="history" />
            <button onClick={showAccountModal}>Transaction history</button>
          </StyledRow>
          <StyledRow color="#d34242">
            <IconDisconnect className="disconnect" />
            <button onClick={onDisconnect}>Disconnect</button>
          </StyledRow>
        </StyledContent>
      </StyledDropdownMenu>
    </Dropdown>
  );
};

const Account = styled.button`
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  border: 1px solid #668382;
  border-radius: 1000px;
  .avatar {
    width: 21px;
    height: 24px;
    margin-right: 8px;
  }
  i {
    margin-left: 6px;
    font-size: 10px;
    margin-bottom: 4px;
  }
  :hover {
    background-color: #126260;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-top: 10px;
  width: 225px;
  min-width: auto;
  ::after {
    content: '';
    background: none;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e7e7;
  font-size: 18px;
  font-weight: 600;
  color: #16182c;
  line-height: 1;
  .avatar {
    margin-right: 8px;
  }
  button {
    margin-left: auto;
    color: #93c1bf;
    i {
      font-size: 16px;
    }
    :hover {
      color: #318e8b;
    }
  }
`;

const StyledContent = styled.div`
  padding-top: 20px;
`;

const StyledRow = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  button,
  a {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    color: ${({ color }) => color || '#16182c'};
    :hover {
      color: ${({ color }) => color || '#318e8b'};
    }
  }
  .external {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .history {
    width: 16px;
    height: 17px;
    margin-right: 10px;
  }
  .disconnect {
    width: 18px;
    height: 13px;
    margin-right: 10px;
  }
  :not(:last-child) {
    padding-bottom: 30px;
  }
`;

export default ButtonAccount;
