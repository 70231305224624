import { useWallet } from '@solana/wallet-adapter-react';
import styled from 'styled-components';
import useModal from '../../hooks/useModal';
import ModalSelectWallet from '../ModalAccount/ModalSelectWallet';
import { NavBar } from './NavBar';
import logo from '../../assets/images/logo.png';
import iconArrow from '../../assets/icons/arrow.png';
import { TokenSymbol } from '../TokenSymbol';
import { useSelector } from 'react-redux';
import { BigNumberValue } from '../BigNumberValue';
import ButtonAccount from './ButtonAccount';
import { screenUp } from '../../utils/styles';
import { useSetMainNavOpen } from '../../state/application/hooks';
import { useCallback } from 'react';
import { selectTokenPrice } from '../../state/token/selectors';

const Header: React.FC = () => {
  const tokenPrice = useSelector(selectTokenPrice);
  const wallet = useWallet();
  const [showSelectWalletModal] = useModal(<ModalSelectWallet />);
  const toggleMainNav = useSetMainNavOpen();

  const onToggleClick = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      toggleMainNav(true);
    },
    [toggleMainNav],
  );

  return (
    <StyledHeader>
      <NavBar />
      <ButtonToggleMenu className="far fa-bars" onClick={onToggleClick} />
      <StyledLogo src={logo} />
      <StyledUser>
        <StyledPrice>
          <TokenSymbol symbol="ICE" size={42} />
          <BigNumberValue value={tokenPrice?.share} decimals={6} /> USDC
        </StyledPrice>
        {wallet.connected ? (
          <ButtonAccount />
        ) : (
          <>
            <StyledButtonConnect onClick={showSelectWalletModal}>
              Connect
              <img src={iconArrow} alt="arrow"/>
            </StyledButtonConnect>
          </>
        )}
      </StyledUser>
    </StyledHeader>
  );
};

export default Header;

const ButtonToggleMenu = styled.i<{ isClose?: boolean }>`
  color: #fff;
  font-size: 24px;
  margin-right: 12px;
  ${screenUp('lg')`
    display: none;
  `}
`;

const StyledHeader = styled.header`
  height: 86px;
  background: #145553;
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  ${screenUp('lg')`
    display: grid;
    grid-template-columns: 1fr 155px 1fr;
    padding: 0 42px;
  `}
`;

const StyledLogo = styled.img`
  width: 120px;
  margin-right: auto;
  ${screenUp('lg')`
    width: 155px;
    margin-right: 0px;
  `}
`;

const StyledUser = styled.div`
  display: flex;
  justify-content: right;
`;

const StyledPrice = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  display: none;
  img {
    margin-right: 10px;
  }
  ${screenUp('lg')`
    display: flex;
  `}
`;

const StyledButtonConnect = styled.button`
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #318e8b;
  border-radius: 1000px;
  img {
    width: 12px;
    margin-left: 6px;
  }
  :hover {
    background-color: #268783;
  }
`;
