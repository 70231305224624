import FarmUpdater from './farm/updater';
import TokenUpdater from './token/updater';
import ApplicationUpdater from './application/updater';

const Updaters: React.FC = () => {
  return (
    <>
      <FarmUpdater />
      <TokenUpdater />
      <ApplicationUpdater />
    </>
  );
};

export default Updaters;
