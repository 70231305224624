import { BN, Program, Provider, web3 } from '@project-serum/anchor';
import { createTokenAccountInstrs, getTokenAccount } from '@project-serum/common';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair, PublicKey } from '@solana/web3.js';
import StableCoinIdl from '../idl/stable-coin.json';
import OracleIdl from '../idl/twap-oracle.json';
import { Precision } from '../utils/bignumber';
import { PoolAccount, PriceAccount } from './accounts';
import { ORACLE_PROGRAM_ID, STABLE_COIN_PROGRAM_ID } from './constants';

export class Pool {
  program: Program;
  address: PublicKey;
  oracle: Program;

  constructor(address: string, private provider: Provider) {
    this.address = new PublicKey(address);
    this.program = new Program(StableCoinIdl as any, STABLE_COIN_PROGRAM_ID, provider);
    this.oracle = new Program(OracleIdl as any, ORACLE_PROGRAM_ID, provider);
  }

  async info() {
    const data = (await this.program.account.pool.fetch(this.address)) as PoolAccount;

    const [sharePrice, dollarPrice, collateralBalance] = await Promise.all([
      this.fetchPrice(data.sharePrice),
      this.fetchPrice(data.dollarPrice),
      await getTokenAccount(this.provider, data.vault).then((x) => x.amount),
    ]);

    return {
      ...data,
      sharePrice,
      dollarPrice,
      collateralBalance: collateralBalance.sub(data.feeAmount),
      dollarOracle: data.dollarPrice,
      shareOracle: data.sharePrice,
    };
  }

  estimateMint(
    {
      collateral,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (collateral) {
      ret.share = collateral.mul(Precision.sub(cr)).mul(Precision).div(cr).div(sharePrice);
      ret.dollar = collateral
        .mul(Precision)
        .mul(Precision.sub(fee))
        .mul(Precision.sub(slippage))
        .div(cr)
        .div(Precision)
        .div(Precision); // remove missing decimals for simplicity
    }

    return ret;
  }

  estimateMintByShare(
    {
      share,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (share) {
      ret.collateral = share.mul(sharePrice).mul(cr).div(Precision.sub(cr)).div(Precision);
      ret.dollar = share
        .mul(sharePrice)
        .mul(Precision.sub(fee))
        .mul(Precision.sub(slippage))
        .div(Precision.sub(cr))
        .div(Precision)
        .div(Precision);
    }

    return ret;
  }

  estimateMintByDollar(
    {
      dollar,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (dollar) {
      ret.collateral = dollar
        .mul(cr)
        .mul(Precision)
        .div(Precision.sub(fee))
        .div(Precision.sub(slippage));
      ret.share = dollar
        .mul(Precision.sub(cr))
        .mul(Precision)
        .mul(Precision)
        .div(Precision.sub(fee))
        .div(Precision.sub(slippage))
        .div(sharePrice);
    }

    return ret;
  }

  estimateRedeem(
    {
      dollar,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (dollar) {
      ret.collateral = dollar
        .mul(Precision.sub(fee))
        .mul(Precision.sub(slippage))
        .mul(cr)
        .div(Precision)
        .div(Precision)
        .div(Precision); // remove missing decimals for simplicity
      ret.share = dollar
        .mul(Precision.sub(cr))
        .mul(Precision)
        .mul(Precision.sub(slippage))
        .div(Precision)
        .div(sharePrice)
        .div(Precision);
    }

    return ret;
  }

  estimateRedeemByShare(
    {
      share,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (share) {
      ret.collateral = share.mul(sharePrice).mul(cr).div(Precision.sub(cr)).div(Precision);
      ret.dollar = share
        .mul(sharePrice)
        .mul(Precision)
        .mul(Precision)
        .div(Precision.sub(fee))
        .div(Precision.sub(slippage))
        .div(Precision.sub(cr));
    }

    return ret;
  }

  estimateRedeemByCollateral(
    {
      collateral,
    }: {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    },
    sharePrice: BN,
    cr: BN,
    fee: BN,
    slippage: BN,
  ) {
    const ret = {} as {
      collateral?: BN;
      share?: BN;
      dollar?: BN;
    };
    if (collateral) {
      ret.share = collateral.mul(Precision.sub(cr)).mul(Precision).div(cr).div(sharePrice);
      ret.dollar = collateral
        .mul(Precision)
        .mul(Precision)
        .mul(Precision)
        .div(Precision.sub(fee))
        .div(Precision.sub(slippage))
        .div(cr);
    }

    return ret;
  }

  async mint(
    user: PublicKey,
    userCollateral: PublicKey,
    userShare: PublicKey,
    userDollar: PublicKey,
    collateral: BN,
    share: BN,
    expectedDollar: BN,
  ) {
    const poolInfo = await this.poolInfo();

    let instructions = [] as any[];
    let signers = [];

    if (!userDollar) {
      const account = Keypair.generate();
      instructions = instructions.concat(
        await createTokenAccountInstrs(this.provider, account.publicKey, poolInfo.dollar, user),
      );
      signers.push(account);
      userDollar = account.publicKey;
    }

    if (!userShare) {
      const account = Keypair.generate();
      instructions = instructions.concat(
        await createTokenAccountInstrs(this.provider, account.publicKey, poolInfo.share, user),
      );
      signers.push(account);
      userShare = account.publicKey;
    }

    const [poolAuthority] = await PublicKey.findProgramAddress(
      [this.address.toBuffer()],
      STABLE_COIN_PROGRAM_ID,
    );

    return await this.program.rpc.mint(collateral, share, expectedDollar, {
      accounts: {
        pool: this.address,
        user: user,
        userCollateral,
        userDollar,
        userShare,
        collateral: poolInfo.collateral,
        share: poolInfo.share,
        dollar: poolInfo.dollar,
        vault: poolInfo.vault,
        poolAuthority: poolAuthority,
        tokenProgram: TOKEN_PROGRAM_ID,
        sharePrice: poolInfo.sharePrice,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
      },
      signers,
      instructions: instructions.length ? instructions : undefined,
    });
  }

  async redeem(
    user: PublicKey,
    userCollateral: PublicKey,
    userShare: PublicKey,
    userDollar: PublicKey,
    dollarAmt: BN,
    shareAmt: BN,
    collateralAmt: BN,
  ) {
    const poolInfo = await this.poolInfo();

    let instructions = [] as any[];
    let signers = [];

    if (!userDollar) {
      const account = Keypair.generate();
      instructions = instructions.concat(
        await createTokenAccountInstrs(this.provider, account.publicKey, poolInfo.dollar, user),
      );
      signers.push(account);
      userDollar = account.publicKey;
    }

    if (!userShare) {
      const account = Keypair.generate();
      instructions = instructions.concat(
        await createTokenAccountInstrs(this.provider, account.publicKey, poolInfo.share, user),
      );
      signers.push(account);
      userShare = account.publicKey;
    }

    const [poolAuthority] = await PublicKey.findProgramAddress(
      [this.address.toBuffer()],
      STABLE_COIN_PROGRAM_ID,
    );

    return await this.program.rpc.redeem(dollarAmt, shareAmt, collateralAmt, {
      accounts: {
        pool: this.address,
        user: user,
        userCollateral,
        userDollar,
        userShare,
        collateral: poolInfo.collateral,
        share: poolInfo.share,
        dollar: poolInfo.dollar,
        vault: poolInfo.vault,
        poolAuthority: poolAuthority,
        tokenProgram: TOKEN_PROGRAM_ID,
        sharePrice: poolInfo.sharePrice,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
      },
      signers,
      instructions: instructions.length ? instructions : undefined,
    });
  }

  private async fetchPrice(oracle: PublicKey) {
    const data = (await this.oracle.account.price.fetch(oracle)) as PriceAccount;
    return data.tokenPrice;
  }

  private async poolInfo() {
    return (await this.program.account.pool.fetch(this.address)) as PoolAccount;
  }
}
