import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TokenInputWithMaxButton } from '../../components/TokenInput';
import BN from 'bn.js';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from '../../components/Dropdown';
import { MouseEvent } from 'react';
import { BigNumberValue } from '../../components/BigNumberValue';
import { selectPairSwap, TokenBySymbol } from '../../config/tokens';
import { Zero } from '../../utils/bignumber';
import { useTokenSwap } from '../../providers/ChainProvider';
import { PublicKey } from '@solana/web3.js';
import { useSlippage } from '../../state/application/hooks';
import { useTransactionHandler } from '../../hooks/useTransactionHandler';
import { useWallet } from '@solana/wallet-adapter-react';
import { useSelector } from 'react-redux';
import { AppState } from '../../state';
import { TokenSelectors } from '../../state/token/selectors';
import { Button } from '../../components/Button';
import { TokenSymbol } from '../../components/TokenSymbol';
import {
  Balance,
  Box,
  ButtonContainer,
  StyledInputContainer,
  TokenInputHeader,
  TokenLabel,
} from '../../components/Box';
import When from '../../components/When';
import { useSwapInfo } from '../../hooks/useSwapInfo';

const tokens = ['USDC', 'IRON', 'ICE'];

const Swap: React.FC = () => {
  const [input, setInput] = useState<string>('USDC');
  const [output, setOutput] = useState<string>('ICE');
  const [amount, setAmount] = useState<BN>();
  const [estimateOutput, setEstimateOutput] = useState<BN>();
  const tokenSwap = useTokenSwap();
  const tokenList = useSelector(TokenSelectors.selectEntities);
  

  const selectInput = useCallback((e: MouseEvent<HTMLElement>) => {
    const token = (e.target as HTMLElement).dataset.token;
    setInput(token);
  }, []);

  const selectOutput = useCallback((e: MouseEvent<HTMLElement>) => {
    const token = (e.target as HTMLElement).dataset.token;
    setOutput(token);
  }, []);

  const inputBalance = useMemo(() => {
    const address = TokenBySymbol[input]?.address;
    return tokenList[address]?.balance
      ? new BN(tokenList[address]?.balance)
      : null;
  }, [tokenList, input]);

  const outputBalance = useMemo(() => {
    const address = TokenBySymbol[output]?.address;
    return tokenList[address]?.balance
      ? new BN(tokenList[address]?.balance)
      : null;
  }, [tokenList, output]);

  const pair = useMemo(() => selectPairSwap(input, output), [input, output]);
  const swapInfo = useSwapInfo(pair)

  const slippage = useSlippage();

  useEffect(() => {
    const inputAddress = TokenBySymbol[input]?.address;

    if (!pair || !inputAddress || !amount || amount.eq(Zero) || !tokenSwap || !swapInfo) {
      setEstimateOutput(null);
      return;
    }

    setEstimateOutput(tokenSwap
      .estimate(
        new PublicKey(inputAddress),
        amount,
        slippage,
        swapInfo
      ));
  }, [pair, amount, input, tokenSwap, slippage, swapInfo]);

  const handleTransaction = useTransactionHandler();
  const wallet = useWallet();
  const userInput = useSelector((s: AppState) => {
    const inputAddress = TokenBySymbol[input]?.address;
    return s.token.list.entities[inputAddress]
      ? new PublicKey(s.token.list.entities[inputAddress].tokenAccountAddress)
      : null;
  });

  const userOutput = useSelector((s: AppState) => {
    const outputAddress = TokenBySymbol[output]?.address;
    return s.token.list.entities[outputAddress]
      ? new PublicKey(s.token.list.entities[outputAddress].tokenAccountAddress)
      : null;
  });

  const swap = useCallback(() => {
    if (!wallet.connected) {
      return;
    }

    const inputAddress = TokenBySymbol[input]?.address;
    handleTransaction(
      tokenSwap.swap(
        new PublicKey(pair),
        wallet.publicKey,
        userInput,
        userOutput,
        new PublicKey(inputAddress),
        amount,
        estimateOutput,
      ),
      {
        type: 'swap',
        input,
        output,
        amount,
      },
    );
  }, [
    amount,
    estimateOutput,
    handleTransaction,
    input,
    output,
    pair,
    tokenSwap,
    userInput,
    userOutput,
    wallet,
  ]);

  return (
    <StyledContainer>
      <h2>Swap</h2>
      <Box>
        <StyledInputContainer>
          <TokenInputHeader>
            <Dropdown>
              <DropdownToggle>
                <TokenLabel>
                  <TokenSymbol symbol={input} />
                  <strong>{input}</strong>
                  <i className="fas fa-caret-down"></i>
                </TokenLabel>
              </DropdownToggle>
              <DropdownMenu>
                <StyledDropdownList>
                  {tokens.map((t) => (
                    <li key={t} onClick={selectInput} data-token={t}>
                      <TokenSymbol size={28} symbol={t} /> {t}
                    </li>
                  ))}
                </StyledDropdownList>
              </DropdownMenu>
            </Dropdown>
            <When condition={!!inputBalance}>
              <Balance>
                <BigNumberValue value={inputBalance} decimals={6} />
              </Balance>
            </When>
          </TokenInputHeader>

          <div>
            <TokenInputWithMaxButton
              value={amount}
              maxValue={inputBalance}
              onChange={setAmount}
              decimals={6}
            />
          </div>
        </StyledInputContainer>

        <ArrowDown>
          <i className="far fa-arrow-down"></i>
        </ArrowDown>

        <StyledInputContainer>
          <TokenInputHeader>
            <Dropdown>
              <DropdownToggle>
                <TokenLabel>
                  <TokenSymbol symbol={output} />
                  <strong>{output}</strong>
                </TokenLabel>
              </DropdownToggle>
              <DropdownMenu>
                <StyledDropdownList>
                  {tokens.map((t) => (
                    <li key={t} onClick={selectOutput} data-token={t}>
                      <TokenSymbol size={28} symbol={t} /> {t}
                    </li>
                  ))}
                </StyledDropdownList>
              </DropdownMenu>
            </Dropdown>
            <When condition={!!outputBalance}>
              <Balance>
                <BigNumberValue value={outputBalance} decimals={6} />
              </Balance>
            </When>
          </TokenInputHeader>
          <TokenInputWithMaxButton
            value={estimateOutput}
            decimals={6}
            disabled
          />
        </StyledInputContainer>

        <ButtonContainer>
          <Button block onClick={swap}>
            Swap
          </Button>
        </ButtonContainer>
      </Box>
    </StyledContainer>
  );
};

export default Swap;

const StyledContainer = styled.div`
  margin: auto;
  max-width: 32em;
`;

const ArrowDown = styled.div`
  text-align: center;
  padding: 20px;
`;

const StyledDropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #d0dddd;
    }
  }
`;
