import { BN } from '@project-serum/anchor';
import { nanoid } from '@reduxjs/toolkit';
import { useConnection } from '@solana/wallet-adapter-react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { formatBigNumber } from '../components/BigNumberValue';
import { useAddPopup } from '../state/application/hooks';
import { transcationSubmitted, transactionConfirmed } from '../state/transaction/actions';
import { Zero } from '../utils/bignumber';

export type Params =
  | {
      type: 'mint';
      dollar: BN;
      share: BN;
      collateral: BN;
    }
  | {
      type: 'redeem';
      dollar: BN;
      share: BN;
      collateral: BN;
    }
  | {
      type: 'chef:deposit';
      amount: BN;
      symbol: string;
    }
  | {
      type: 'chef:withdraw';
      amount: BN;
      symbol: string;
    }
  | {
      type: 'chef:claim';
      amount: BN;
      symbol: string;
    }
  | {
      type: 'swap';
      input: string;
      output: string;
      amount: BN;
    }
  | {
      type: 'swap:addLiquidity';
      tokenA: string;
      tokenB: string;
      tokenAAmount: BN;
      tokenBAmount: BN;
    }
  | {
      type: 'admin:refreshCollateralRatio';
    }
  | {
      type: 'admin:updateOracle';
    }
  | {
      type: 'admin:setCollateralCooldownTime';
    }
  | {
      type: 'admin:toggleEcr';
    };

const toSummary = (params: Params) => {
  switch (params.type) {
    case 'mint':
      return (
        `Mint ${formatBigNumber(params.dollar, 6)} IRON from ${formatBigNumber(
          params.collateral,
          6,
        )}USDC` + (params.share.gt(Zero) ? ` and ${formatBigNumber(params.share, 6)}ICE` : '')
      );

    case 'redeem':
      return (
        `Redeem ${formatBigNumber(params.dollar, 6)} IRON to ${formatBigNumber(
          params.collateral,
          6,
        )}USDC` + (params.share.gt(Zero) ? ` and ${formatBigNumber(params.share, 6)}ICE` : '')
      );
    case 'chef:deposit':
      return `Deposit ${formatBigNumber(params.amount, 6)} ${params.symbol}`;
    case 'chef:withdraw':
      return `Withdraw ${formatBigNumber(params.amount, 6)} ${params.symbol}`;
    case 'chef:claim':
      return `Claim ${formatBigNumber(params.amount, 6)} ${params.symbol}`;
    case 'swap':
      return `Swap ${formatBigNumber(params.amount, 6)} ${params.input} to ${params.output}`;
    case 'swap:addLiquidity':
      return `Add ${formatBigNumber(params.tokenAAmount, 6)} ${
        params.tokenA
      } and ${formatBigNumber(params.tokenBAmount, 6)} ${params.tokenB}`;
    case 'admin:refreshCollateralRatio':
      return 'Refresh Colalteral ratio';
    case 'admin:updateOracle':
      return 'Update oralce';
    case 'admin:setCollateralCooldownTime':
      return 'Update CR cooldown time';
    case 'admin:toggleEcr':
      return 'Toggle ECR';

    default:
      return '';
  }
};

export const useTransactionHandler = () => {
  const dispatch = useDispatch();
  const { connection } = useConnection();
  const addPopup = useAddPopup();

  return useCallback(
    async (tx: Promise<string>, params: Params, extras?: any) => {
      const summary = toSummary(params);
      const popupId = nanoid();
      addPopup(
        {
          type: 'waiting',
          title: 'Waiting for confirmation',
          message: summary,
        },
        popupId,
      );

      try {
        const hash = await tx;
        dispatch(
          transcationSubmitted({
            hash,
            summary,
            extras: JSON.stringify(extras),
            popupId,
          }),
        );
        addPopup(
          {
            type: 'transaction',
            hash: hash,
          },
          popupId,
          true,
        );
        const response = await connection.confirmTransaction(hash, 'confirmed');
        const error = response.value.err;
        dispatch(
          transactionConfirmed({
            hash,
            error: error?.toString(),
          }),
        );

        if (error) {
          addPopup(
            {
              type: 'error',
              title: summary,
              message: error.toString(),
            },
            popupId,
          );
        } else {
          addPopup(
            {
              type: 'transaction',
              hash: hash,
            },
            popupId,
          );
        }
      } catch (e) {
        console.debug(e);
        addPopup(
          {
            type: 'error',
            title: 'Transaction not submited',
            message: e.toString(),
          },
          popupId,
        );

        throw e;
      }
    },
    [addPopup, connection, dispatch],
  );
};
