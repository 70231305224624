import styled from 'styled-components';
import { screenUp } from '../utils/styles';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 15px;
  max-width: 1000px;
  margin: auto;
  ${screenUp('lg')`
    min-height: calc(100vh - 86px);
    padding: 35px 20px;
  `}
`;
