import styled from 'styled-components';
import { screenUp } from '../../utils/styles';

export { default, Context } from './Modals';

type ModalSize = 'xs' | 'sm' | 'md' | 'lg';

const getModalSize = (size: ModalSize = 'sm') => {
  switch (size) {
    case 'xs':
      return 450;
    case 'sm':
      return 500;
    case 'md':
      return 800;
    case 'lg':
      return 1140;
    default:
      return 300;
  }
};

export const Modal = styled.div<{ size?: ModalSize }>`
  margin: 0;
  width: 100%;
  max-width: ${(p) => getModalSize(p.size)}px;
  z-index: 1000;
  border-radius: 5px;
  border: 1px solid #becfd4;
  background-color: #f4f4f4;
  ${screenUp('lg')`
    margin: 0 auto;
  `};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 20px;
  border-bottom: 1px solid #e6e7e7;
`;

export const ModalTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  color: #16182c;
`;

export const ModalBody = styled.div`
  padding: 20px;
`;

export const ModalFooter = styled.div``;

export const ModalClose = styled.button.attrs({
  children: <i className="fal fa-times" />,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  order: 9;
  color: #7a797f;
  font-size: 1.2rem;
  margin-left: auto;
  width: 24px;
  height: 24px;
  i {
    color: #7a797f;
  }
  :hover {
    i {
      color: #16182c;
    }
  }
`;
