import { keyBy } from 'lodash';

export const tokens = [
  {
    symbol: 'ICE',
    address: 'GcM75i876xGk5qRB5JBkMrNjP7QLnibLEXVKN5g388ZS',
    decimals: 6,
  },
  {
    symbol: 'IRON',
    address: '96cTnh9hW7aDV8MesnNHHgiigpHHVsprvUuaTBPqQ2gJ',
    decimals: 6,
  },
  {
    symbol: 'USDC',
    address: 'GzE7Ugwi5BMjbDHM554r2wvx7iVuneCrDNw57KQJtpti',
    decimals: 6,
  },
  {
    symbol: 'IRON_USDC_LP',
    address: '7TDkSRUo2t7xiPdZ7H7HCLFFNk3FTYVZM1uy4Y3i6LKR',
    decimals: 6,
  },
  {
    symbol: 'ICE_USDC_LP',
    address: '32zQuGR3q6ysdn1exJ1RZHR2GaPSHSpQ4GeCGNX3TrkW',
    decimals: 6,
  },
];

export const TokenByAddress = keyBy(tokens, (t) => t.address);

export const TokenBySymbol = keyBy(tokens, (t) => t.symbol);

export type Pair = {
  tokenA: string;
  tokenB: string;
  swap: string;
  lp: string;
};

export const AllPools = [
  {
    swap: '87Vb8M1KMJNhUgKCUKX7dmDuhJxm76DyFdMKyB4Dh1VU',
    tokenA: 'IRON',
    tokenB: 'USDC',
    lp: 'IRON_USDC_LP',
  },
  {
    swap: '5Y4quScBMrY6WJ5AXSZyvyV9a4eVimLMpLj2LPRcdWRy',
    tokenA: 'ICE',
    tokenB: 'USDC',
    lp: 'ICE_USDC_LP',
  },
];

export const selectPair = (input: string, output: string) => {
  return AllPools.filter((t) => {
    return (
      (t.tokenA === input && t.tokenB === output) ||
      (t.tokenB === input && t.tokenA === output)
    );
  })[0]
};

export const selectPairSwap = (input: string, output: string) => {
  return AllPools.filter((t) => {
    return (
      (t.tokenA === input && t.tokenB === output) ||
      (t.tokenB === input && t.tokenA === output)
    );
  }).map((t) => t.swap)[0];
};
