import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;
 
export const Balance = styled.span`
  font-size: 0.875em;
`;

export const StyledInputContainer = styled.div`
  padding: 16px 16px 10px;
  border-radius: 3px;
  background-color: #fff;
`;

export const TokenLabel = styled.div`
  display: flex;
  align-items: center;
  strong {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const TokenInputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const Box = styled.div`
  padding: 30px 25px;
  border-radius: 5px;
  border: 1px solid #becfd4;
  background-color: #f4f4f4;
`;