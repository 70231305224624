import { createAction } from '@reduxjs/toolkit';

export const farmInfoFetched = createAction<{
  reward: string;
  rewardPerSecond: string;
  totalAllocPoint: string;
  pools: Array<{
    id: number;
    allocPoint: string;
    totalStaked: string;
    want: string;
    vaultAddress: string;
    accRewardPerShare: string;
    lastRewardTime: number;
  }>;
}>('farm/farmInfoFetched');

export const farmUserInfoFetched = createAction<
  Array<{
    stakeAccount: string;
    amount: string;
    poolId: number;
    rewardDebt: string;
  }>
>('farm/farmUserInfoFetched');
