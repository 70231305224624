import { createGlobalStyle } from 'styled-components';
import bgTop from '../assets/images/bg-top.webp';
import bgBottom from '../assets/images/bg-bottom.webp';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    position: relative;
    margin: 0;
    background-color: #d9d9d9;
    color: #16182c;
    min-height: 100vh;
    &:after {
      content: '';
      top: 86px;
      right: 0;
      width: 20%;
      height: calc(100vh - 86px);
      position: absolute;
      background-image: url(${bgTop});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
      z-index: 0;
    }
    &:before {
      content: '';
      bottom: 0;
      left: 0;
      width: 20%;
      height: calc(100vh - 86px);
      position: absolute;
      background-image: url(${bgBottom});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom left;
      z-index: 0
    }
  }

  button {
    font-family: inherit;
    border: none;
    background: transparent;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }

    :disabled {
      pointer-events: none;
    }
  }

  input {
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }

  a, a:visited {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  ul, ol {
    list-style: none;
  }

  .no-scroll {
    overflow: hidden;
  }

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  @keyframes dots {
    0% {
      content: '.'
    }
    20% {
      content: '.'
    }
    40% {
      content: '..'
    }
    60% {
      content: '...'
    }
    90% {
      content: ''
    }
    100% {
      content: ''
    }
  }

  .btn-loading {
    &:after {
      content: ' ';
      text-align: left;
      width: 1rem;
      animation: dots 1.4s linear infinite;
      display: inline-block;
    }
  }
`;
