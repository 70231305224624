import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

type Configuration = {
  farm: {
    chef: string;
  };
  stableCoin: {
    pool: string;
    oracles: {
      share: string;
      dollar: string;
    };
  };
  network: WalletAdapterNetwork;
};

const config: Configuration = {
  farm: {
    chef: '69wMLykTL3wpDsWYrRcUMpL9MYecMk39JvitykotiHkr',
  },
  stableCoin: {
    pool: '2YP6ZKTmHBhfHtn5Qnkey2FVGDQ9CfXMFF988sCNJHXS',
    oracles: {
      share: '2sGawoz7Hx1tWsNpjuR73aFaaZmZqjbRrvh9nJLenPgh',
      dollar: '4qrxhs3gg3yde3kbCQcMCDEZbrg93ycAACciMX2X8mir',
    },
  },
  network: WalletAdapterNetwork.Devnet,
};

export default config;
