import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Chain, IChain } from '../../src/model/Chain';
import { useProvider } from './Web3Provider';

const Context = createContext<IChain>(null);

// TODO: choose another name
const ChainProvider: React.FC = ({ children }) => {
  const [chain, setChain] = useState<IChain>();
  const provider = useProvider();

  useEffect(() => {
    const p = new Chain(provider);
    setChain(p);
  }, [provider]);

  return <Context.Provider value={chain}>{children}</Context.Provider>;
};

export default ChainProvider;

export const usePool = () => {
  const context = useContext(Context);
  return useMemo(() => context?.getPool(), [context]);
};

export const useChef = () => {
  const context = useContext(Context);
  return useMemo(() => context?.getChef(), [context]);
};

export const useTokenSwap = () => {
  const context = useContext(Context);
  return useMemo(() => context?.getTokenSwap(), [context]);
};

export const usePoolManager = () => {
  const context = useContext(Context);
  return useMemo(() => context?.getPoolManager(), [context]);
};

export const useOracle = () => {
  const context = useContext(Context);
  return useMemo(() => context?.getOracle(), [context]);
};
