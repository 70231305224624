import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getLedgerWallet,
  getMathWallet,
} from '@solana/wallet-adapter-wallets';
import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import Header from './components/Layout/Header';
import store from './state';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Updaters from './state/updaters';
import Web3Provider from './providers/Web3Provider';
import ChainProvider from './providers/ChainProvider';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import Modals from './providers/Modals';
import { Theme } from './providers/Theme/Theme';
import { Container } from './components/Container';
import config from './config';
import { Popups } from './components/Popups';
import Swap from './views/Swap';
import AddLiquidity from './views/AddLiquidity';

const App: React.FC = () => {
  return (
    <Providers>
      <Header />
      <Container>
        <Switch>
          <Route path="/" exact>
            <Swap />
          </Route>
          <Route path="/liquidity">
            <AddLiquidity />
          </Route>
        </Switch>
      </Container>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  const network = config.network || WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getMathWallet(),
    ],
    [],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <Web3Provider>
          <ChainProvider>
            <Provider store={store}>
              <Theme>
                <Popups />
                <Modals>
                  <Updaters />
                  <BrowserRouter>{children}</BrowserRouter>
                </Modals>
              </Theme>
            </Provider>
          </ChainProvider>
        </Web3Provider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
