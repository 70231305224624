import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import application from './application/reducer';
import farm from './farm/reducer';
import token from './token/reducer';
import transaction from './transaction/reducer';

const PersistentState = ['application.user', 'transaction'];
const PersistentNamespace = '__iron';

const store = configureStore({
  reducer: {
    application,
    farm,
    token,
    transaction,
  },
  middleware: (getDefaultMiddlewares) => [
    ...getDefaultMiddlewares(),
    save({ states: PersistentState, namespace: PersistentNamespace }),
  ],
  preloadedState: load({
    states: PersistentState,
    namespace: PersistentNamespace,
    preloadedState: {},
  }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
