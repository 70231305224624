import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../components/GlobalStyle';
import dark from './dark';
export const Theme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={dark}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
