import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useChef } from '../../providers/ChainProvider';
import { useLastUpdate } from '../application/hooks';
import { farmInfoFetched, farmUserInfoFetched } from './actions';

const Updater: React.FC = () => {
  const chef = useChef();
  const dispatch = useDispatch();
  const wallet = useWallet();
  const lastUpdate = useLastUpdate();

  useEffect(() => {
    if (!chef) {
      return;
    }

    chef.info().then((res) => {
      dispatch(
        farmInfoFetched({
          reward: res.reward,
          rewardPerSecond: res.rewardPerSecond.toString(),
          totalAllocPoint: res.totalAllocPoint.toString(),
          pools: res.poolInfos.map((t) => {
            return {
              id: t.id,
              allocPoint: t.allocPoint.toString(),
              totalStaked: t.totalStaked.toString(),
              want: t.want,
              vaultAddress: t.vaultAddress.toString(),
              accRewardPerShare: t.accRewardPerShare.toString(),
              lastRewardTime: t.lastRewardTime,
            };
          }),
        }),
      );
    });
  }, [chef, dispatch, lastUpdate]);

  useEffect(() => {
    if (wallet.connected && chef) {
      chef.userInfo(wallet.publicKey).then((res) => {
        dispatch(
          farmUserInfoFetched(
            res.map((t) => ({
              stakeAccount: t.address,
              amount: t.amount.toString(),
              poolId: t.poolId,
              rewardDebt: t.rewardDebt.toString(),
            })),
          ),
        );
      });
    }
  }, [chef, dispatch, wallet, lastUpdate]);

  return null;
};

export default Updater;
