import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useBodyClass } from '../../hooks/useBodyClass';
import {
  useIsMainNavOpen,
  useSetMainNavOpen,
} from '../../state/application/hooks';
import { ExternalLinks } from '../../utils/constants';
import { screenUp } from '../../utils/styles';
import ButtonMore from './ButtonMore';
import logo from '../../assets/images/logo-short-white.png';
import { shortenAddress } from '../../utils/addresses';
import { useWallet } from '@solana/wallet-adapter-react';
import { ReactComponent as IconDocument } from '../../assets/icons/document-white.svg';
import { ReactComponent as IconDiscord } from '../../assets/icons/discord-white.svg';
import { ReactComponent as IconGithub } from '../../assets/icons/github-white.svg';
import { ReactComponent as IconMedium } from '../../assets/icons/medium-white.svg';
import { ReactComponent as IconTelegram } from '../../assets/icons/telegram-white.svg';
import { ReactComponent as IconTwitter } from '../../assets/icons/twitter-white.svg';

export const NavBar: React.FC = () => {
  const { publicKey } = useWallet();
  const isOpen = useIsMainNavOpen();
  const setMainNavOpen = useSetMainNavOpen();
  const shortenAccount = shortenAddress(publicKey?.toString() || '');
  useBodyClass(isOpen, 'no-scroll');
  const [isShowMore, setIsShowMore] = useState(true);

  const toggleShowMore = useCallback(() => {
    setIsShowMore((s) => !s);
  }, []);

  const onClickItem = useCallback(() => {
    if (isOpen) {
      setMainNavOpen(false);
    }
  }, [isOpen, setMainNavOpen]);

  return (
    <StyledContainer open={isOpen}>
      <StyledHeader>
        <StyledLogoNavLink to="/">
          <img src={logo} alt="cSpace"/>
        </StyledLogoNavLink>
        {shortenAccount}
        <StyledButtonClose onClick={onClickItem}>
          <i className="fal fa-times" />
        </StyledButtonClose>
      </StyledHeader>
      <StyledNav>
        <StyledNavItem>
          <StyledNavLink onClick={onClickItem} to="/" exact>
            Swap
          </StyledNavLink>
        </StyledNavItem>
        <StyledNavItem>
          <StyledNavLink onClick={onClickItem} to="/liquidity">
            Liquidity
          </StyledNavLink>
        </StyledNavItem>
        <ButtonMore />
      </StyledNav>
      <StyledMore>
        <StyledMoreHeader onClick={toggleShowMore}>
          More
          <i
            className={isShowMore ? 'far fa-chevron-down' : 'far fa-chevron-up'}
          ></i>
        </StyledMoreHeader>
        <StyledMoreContent open={isShowMore}>
          <StyledNavExternalLink
            href={ExternalLinks.documentations}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconDocument className="icon" />
            Documentations
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
          <StyledNavExternalLink
            href={ExternalLinks.codes}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconGithub className="icon" />
            Codes
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
          <StyledNavExternalLink
            href={ExternalLinks.medium}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconMedium className="icon" />
            Medium
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
          <StyledNavExternalLink
            href={ExternalLinks.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconTwitter className="icon" />
            Twitter
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
          <StyledNavExternalLink
            href={ExternalLinks.telegram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconTelegram className="icon" />
            Telegram
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
          <StyledNavExternalLink
            href={ExternalLinks.discord}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconDiscord className="icon" />
            Discord
            <i className="far fa-external-link" />
          </StyledNavExternalLink>
        </StyledMoreContent>
      </StyledMore>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ open?: boolean }>`
  position: fixed;
  margin: 0;
  padding: 21px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  transform: ${(p) => (p.open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.2s linear;
  z-index: 999;
  background-color: #145553;
  ${screenUp('lg')`
    padding: 0px;
    position: inherit;
    z-index: 1;
    transform: none;
    background-color: transparent;
  `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #1c6664;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  ${screenUp('lg')`
    display: none;
  `}
`;

const StyledLogoNavLink = styled(NavLink)`
  img {
    width: 45px;
    margin-right: 12px;
  }
`;

const StyledButtonClose = styled.button`
  margin-left: auto;
  i {
    color: #ffffff;
    font-size: 20px;
  }
`;

const StyledNav = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  ${screenUp('lg')`
    flex-direction: row;
  `}
`;

const StyledNavItem = styled.li`
  padding: 0;
  margin: 0;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #d6e6dd;
  &.active {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  :hover {
    font-size: 16px;
    color: #fff;
  }
  height: fit-content;
  justify-content: flex-start;
  padding-bottom: 20px;
  ${screenUp('lg')`
    height: 86px;
    justify-content: center;
    padding-bottom: 0px;
    margin: 0px 18px;
    &.active {
      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        top: 0;
        background-image: linear-gradient(to bottom, #06ac95 -26%, rgba(6, 172, 149, 0));
      }
    }
  `}
`;

const StyledMore = styled.div`
  ${screenUp('lg')`
    display: none;
  `}
`;

const StyledMoreHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  i {
    font-size: 14px;
  }
`;

const StyledMoreContent = styled.div<{ open?: boolean }>`
  padding: 5px 0 0 8px;
  transform-origin: top;
  transform: ${(p) => (p.open ? 'scaleY(0)' : 'scaleY(1)')};
  transition: transform 0.2s linear;
`;

const StyledNavExternalLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  padding: 10px 0;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  i {
    margin-left: auto;
    font-size: 12px;
  }
  :not(:last-child) {
    border-bottom: 1px dashed #3e7373;
  }
`;
