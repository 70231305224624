import BN from 'bn.js';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from '..';
import { addPopup, removePopup, setSlippageTolerance, toggleMainNav } from './actions';
import { PopupContent } from './reducer';

export const useLastUpdate = () => useSelector((s: AppState) => s.application.lastUpdate);

const selectSlippage = createSelector(
  [(s: AppState) => s.application.slippage],
  (s) => new BN(s * 1e6),
);

export const useLastSlippageValue = () => useSelector((s: AppState) => s.application.slippage);

export const useSlippage = () => useSelector(selectSlippage);

export const useIsMainNavOpen = () => {
  return useSelector<AppState, boolean>((s) => s.application.mainNavOpen);
};

export const useSetMainNavOpen = () => {
  const dispatch = useDispatch();
  return useCallback(
    (isOpen: boolean) => {
      dispatch(toggleMainNav({ isOpen }));
    },
    [dispatch],
  );
};

export const useSetSlippageTolerance = () => {
  const dispatch = useDispatch();
  return useCallback(
    (slippage: number) => {
      dispatch(setSlippageTolerance({ slippage }));
    },
    [dispatch],
  );
};

export function useActivePopups(): AppState['application']['popupList'] {
  return useSelector((state: AppState) => state.application.popupList.filter((t) => t.show));
}

export function useAddPopup(): (
  content: PopupContent,
  key?: string,
  waiting?: boolean,
) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string, waiting?: boolean) => {
      const removeAfterMs = content.type === 'waiting' || waiting ? null : 5e3;
      dispatch(addPopup({ content, key, removeAfterMs }));
    },
    [dispatch],
  );
}

export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch],
  );
}
