import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from '..';
import { adapter } from './reducer';

export const TransactionSelectors = adapter.getSelectors((s: AppState) => s.transaction);

const selectPendingTransactionCount = createSelector(
  [TransactionSelectors.selectAll],
  (s) => s.filter((s) => !s.confirmed).length,
);

export const usePendingTransactionCount = () => {
  return useSelector(selectPendingTransactionCount);
};

export const selectPendingTransaction = createSelector([TransactionSelectors.selectAll], (s) =>
  s.filter((x) => !x.confirmed),
);
