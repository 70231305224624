import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import { farmInfoFetched, farmUserInfoFetched } from './actions';

type PoolState = {
  id: number;
  want: string;
  allocPoint: string;
  totalStaked: string;
  vaultAddress: string;
  accRewardPerShare: string;
  lastRewardTime: number;
};

type PoolUserState = {
  stakeAccount: string;
  amount: string;
  poolId: number;
  rewardDebt: string;
};

type FarmState = {
  rewardPerSecond: string;
  totalAllocPoint: string;
  pools: EntityState<PoolState>;
  users: EntityState<PoolUserState>;
  reward: string;
};

export const poolAdapter = createEntityAdapter<PoolState>();
export const poolUserAdapter = createEntityAdapter<PoolUserState>({
  selectId: (x) => x.stakeAccount,
});

const initialState = {
  pools: poolAdapter.getInitialState(),
  users: poolUserAdapter.getInitialState(),
} as FarmState;

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(farmInfoFetched, (state, { payload }) => {
      state.pools = poolAdapter.setAll(state.pools, payload.pools);
      state.rewardPerSecond = payload.rewardPerSecond;
      state.totalAllocPoint = payload.totalAllocPoint;
      state.reward = payload.reward;
    })
    .addCase(farmUserInfoFetched, (state, { payload }) => {
      state.users = poolUserAdapter.setAll(state.users, payload);
    });
});

export default reducer;
